import { promises as fs } from "fs";
import React, { useEffect, useState } from "react";
import Wrapper from "layout/wrapper";
import Header from "layout/headers/header";
import Breadcrumb from "components/common/breadcrumb";
import Footer from "layout/footers/footer";
import ShopArea from "components/shop/shop-area";
import { jsonData } from "../home-6/product-data";
import HeaderFour from "layout/headers/header-4";
import { useAppSelector } from "redux/hook";
import BreadcrumbV2 from "components/common/breadcrumb-v2";

export default function ShopThreeColPageV2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileBackgroundStyles = isMobile
    ? {
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }
    : {};

  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const productsBanner = settings[0]?.productsBanner;
  const contactBanner = settings[0]?.contactBanner;

  return (
    <Wrapper>
      <HeaderFour />

      <main>
        <BreadcrumbV2
          mobileBackgroundStyles={mobileBackgroundStyles}
          bannerImg={contactBanner}
          isNewArrivalsPage={true}
        />

        <ShopArea
          isNewArrivalsPage={true}
          shop_col="col-xl-4 col-lg-4 col-md-6 col-sm-6 custom-col-10"
          product_data={jsonData}
        />
      </main>

      <Footer />
    </Wrapper>
  );
}
