import { createSlice } from "@reduxjs/toolkit";
import { sendContactMessage } from "redux/actions/contact.action";
import { notifyError, notifySuccess } from "utils/toast";

type InitialStateTypes = {
  loading: boolean;
};

const initialState: InitialStateTypes = {
  loading: false,
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendContactMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendContactMessage.fulfilled, (state, _) => {
      notifySuccess("Message sent!");
      state.loading = false;
    });
    builder.addCase(sendContactMessage.rejected, (state, action) => {
      notifyError(action.error.message || "Something went wrong");
      state.loading = false;
    });
  },
});

export default contactSlice.reducer;
