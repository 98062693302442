import React from "react";
// import Link from "next/link";
import { Link } from "react-router-dom";
import IBlogType from "types/blog-d-t";
// import Image from "next/image";

const BlogSingle = ({ item }: { item: IBlogType }) => {
  return (
    <div className="blog__item mb-30">
      <div className="blog__thumb fix">
        <Link to={`/blog-details/${item.id}`} className="w-img">
          <img src={item.img} alt="blog" width={352} height={226} />
        </Link>
      </div>
      <div className="blog__content">
        <h4>
          <Link to={`/blog-details/${item.id}`}>{item.title}</Link>
        </h4>
        <div className="blog__meta">
          <span>
            By <a href="#">{item.author}</a>
          </span>
          <span>/ {item.date}</span>
        </div>
        <p>{item.desc}</p>
        <Link to={`/blog-details/${item.id}`} className="os-btn">
          read more
        </Link>
      </div>
    </div>
  );
};

export default BlogSingle;
