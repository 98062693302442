import { BOLTColors } from "config/colors";
import React, { CSSProperties } from "react";
type Props = {};

const titleStyle: CSSProperties = {
  textTransform: "uppercase",
  fontWeight: "lighter",
  fontSize: "20px",
};

const subTitleStyle: CSSProperties = {
  fontWeight: "bold",
  textTransform: "uppercase",
};

export default function SupportV2({
  title = "FOR THE CUSTOMERS",
  sub_title = "BOLT SUPPORT",
}) {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="section__title-wrapper text-center mb-55">
              <div className="mb-10 mt-10">
                <h5 style={titleStyle}>{title}</h5>
                <h2 style={subTitleStyle}>{sub_title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: BOLTColors.supportLightBlueBG }}
        className="newsletter-section section-bg-tb pt-60 pb-80"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="d-flex flex-column align-items-baseline">
                <div className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill={BOLTColors.supportIconsFillGray}
                    className="bi bi-headphones"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V8a6 6 0 1 1 12 0v5a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1V8a5 5 0 0 0-5-5z" />
                  </svg>
                </div>
                <b
                  style={{ fontSize: 14 }}
                  className="align-self-center text-dark"
                >
                  Customer Support
                </b>
                <p
                  style={{ fontSize: 12, lineHeight: 1.5 }}
                  className="align-self-center text-center col-8 text-dark"
                >
                  contact us via live chat, email or phone
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="d-flex flex-column align-items-baseline">
                <div className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill={BOLTColors.supportIconsFillGray}
                    className="bi bi-shield-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                    <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                </div>
                <b
                  style={{ fontSize: 14 }}
                  className="align-self-center text-dark"
                >
                  Warranty
                </b>
                <p
                  style={{ fontSize: 12, lineHeight: 1.5 }}
                  className="align-self-center text-center col-8 text-dark"
                >
                  local warranty policy protection is provide
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="d-flex flex-column align-items-baseline">
                <div className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill={BOLTColors.supportIconsFillGray}
                    className="bi bi-stack"
                    viewBox="0 0 16 16"
                  >
                    <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.598.598 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.598.598 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.598.598 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535L7.733.063z" />
                    <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.598.598 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.659z" />
                  </svg>
                </div>
                <b
                  style={{ fontSize: 14 }}
                  className="align-self-center text-dark"
                >
                  User Guide
                </b>
                <p
                  style={{ fontSize: 12, lineHeight: 1.5 }}
                  className="align-self-center text-center col-8 text-dark"
                >
                  Find and download your bolttec product user guide
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="d-flex flex-column align-items-baseline">
                <div className="align-self-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="80"
                    height="80"
                    fill={BOLTColors.supportIconsFillGray}
                    className="bi bi-question-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                  </svg>
                </div>
                <b
                  style={{ fontSize: 14 }}
                  className="align-self-center text-dark"
                >
                  FAQ
                </b>
                <p
                  style={{ fontSize: 12, lineHeight: 1.5 }}
                  className="align-self-center text-center text-dark"
                >
                  Search for help about bolttec
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
