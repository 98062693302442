//  ITEMS / SEARCH
import React, { CSSProperties, useEffect, useState } from "react"
// import Image from "next/image";
import ShopSidebar from "./shop-sidebar"
import { IProduct } from "types/product-d-t"
import usePagination, { PaginationResult } from "hooks/use-pagination"
import Pagination from "ui/pagination"
import { useAppDispatch, useAppSelector } from "redux/hook"
import ProductItemTwoV2 from "components/products/single-product/product-item-2-v2"
import ProductListItemV2 from "components/products/single-product/product-list-item-v2"
import { OneItem } from "redux/reducers/items.reducer"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { fetchItems } from "redux/actions/items.action"
import { BOLTColors } from "config/colors"

// prop type
type IProps = {
  shop_right?: boolean
  shop_col?: string
  product_data: IProduct[]
  isNewArrivalsPage?: boolean
}

const ShopArea = ({
  product_data,
  shop_right,
  shop_col,
  isNewArrivalsPage = false,
}: IProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { state = null } = useLocation() ?? ""
  const items = useAppSelector((state) => state.items.itemData)
  const itemsLoading = useAppSelector((state) => state.items.loading)

  const newItems = items.filter((item) => item.new === true)

  console.log({ items })

  useEffect(() => {
    if (state?.searchQuery) {
      dispatch(
        fetchItems({
          page: 1,
          search: state?.searchQuery,
          itemsPerPage: 100,
        })
      )
    }
  }, [state?.searchQuery])

  const itemsPerPage = 9

  useEffect(() => {
    if (!state?.searchQuery) {
      dispatch(fetchItems({ itemsPerPage: 1000, page: 1, search: "" }))
    }
  }, [])

  const { currentItems, pageCount, handlePageClick }: PaginationResult<any> =
    usePagination(items ? items : [] ?? [], itemsPerPage)

  const brands = [...new Set(product_data.map((p) => p.brand))]
  const allColors = product_data.flatMap((item) => item.colors)
  const allSizes = product_data.flatMap((item) => item.sizes)
  const uniqueColors = [...new Set(allColors)].slice(0, 8)
  const uniqueSizes = [...new Set(allSizes)]
  const feature_products = product_data.filter((p) => p.trending).slice(0, 2)
  const [filteredItems, setFilteredItems] = useState<any>([])

  const { category, subCategory, sizes, colors, brand, priceValue } =
    useAppSelector((state) => state.filter)
  const [products, setProducts] = useState<IProduct[]>([...product_data])

  // filter
  useEffect(() => {
    let filteredData = [...product_data]
      .filter((p) =>
        category
          ? p.parentCategory.toLowerCase() === category.toLowerCase()
          : true
      )
      .filter((p) =>
        subCategory
          ? p.category.toLowerCase() === subCategory.toLowerCase()
          : true
      )
      .filter((p) => p.price >= priceValue[0] && p.price <= priceValue[1])
      .filter((p) => {
        if (sizes.length > 0) {
          return p.sizes.some((s) => sizes.includes(s))
        }
        return true
      })
      .filter((p) => {
        if (colors.length > 0) {
          return p.colors.some((c) => colors.includes(c))
        }
        return true
      })
      .filter((p) => {
        if (brand) {
          return p.brand.toLowerCase() === brand.toLowerCase()
        }
        return true
      })
    setProducts(filteredData)
  }, [brand, category, colors, priceValue, product_data, sizes, subCategory])

  const titleStyle: CSSProperties = {
    textTransform: "uppercase",
    fontWeight: "lighter",
    fontSize: "20px",
    textAlign: "center",
  }

  const subTitleStyle: CSSProperties = {
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
  }

  const options = ["Select", "New"]

  console.log({ currentItems })

  useEffect(() => {
    console.log({ filteredItems })
  }, [filteredItems])

  return (
    <section className="shop__area pb-100 shop-area-parent">
      {isNewArrivalsPage && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="mb-10 mt-10">
            <h5 style={titleStyle}>{"Discover more latest items"}</h5>
            <h2 style={subTitleStyle}>{"New Arrival"}</h2>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row">
          {!shop_right && !shop_col && (
            <div className="col-xl-3 col-lg-3 col-md-4">
              {/* shop sidebar start */}
              <ShopSidebar
                feature_products={feature_products}
                brands={brands}
                colors={uniqueColors}
                sizes={uniqueSizes}
              />
              {/* shop sidebar end */}
            </div>
          )}
          <div className={shop_col ? "col-xl-12" : "col-xl-9 col-lg-8"}>
            <div className="shop__content-area">
              <div className="shop__header d-sm-flex justify-content-between align-items-center mb-40">
                <div className="shop__header-left">
                  <div className="show-text">
                    {state?.searchQuery && (
                      <span>
                        Showing {items?.length} results{" "}
                        {` ${
                          state?.searchQuery
                            ? "for '" + state?.searchQuery + "'"
                            : ""
                        }`}
                      </span>
                    )}
                    {/* {!state?.searchQuery && (
                      <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                          <span className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </span>
                        </li>
                        <li className="breadcrumb-item">
                          <span className="breadcrumb-item">
                            <Link to="/products">Products</Link>
                          </span>
                        </li>
                      </ol>
                    )} */}
                  </div>
                </div>
                {isNewArrivalsPage ? (
                  <></>
                ) : (
                  <div className="shop__header-right d-flex align-items-center justify-content-between justify-content-sm-end">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "10px",
                            border: "1px solid black",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "black",
                              marginRight: "5px",
                            }}
                          >
                            Sort By:
                          </span>
                          <select
                            onChange={(e) => {
                              if (e.target.value === "New") {
                                setFilteredItems(
                                  currentItems?.filter(
                                    (item) => item.new === true
                                  )
                                )
                              } else {
                                return
                              }
                            }}
                            className="nav-link active"
                            tabIndex={-1}
                          >
                            {options?.map((option, index) => {
                              return (
                                <option
                                  hidden={index === 0}
                                  value={option}
                                  key={index}
                                >
                                  {option}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-grid-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#pills-grid"
                        type="button"
                        role="tab"
                        aria-controls="pills-grid"
                        aria-selected="true"
                        tabIndex={-1}
                      >
                        <i className="fas fa-th"></i>
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-list-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#pills-list"
                        type="button"
                        role="tab"
                        aria-controls="pills-list"
                        aria-selected="false"
                        tabIndex={-1}
                      >
                        <i className="fas fa-list-ul"></i>
                      </button>
                    </li> */}
                    </ul>
                  </div>
                )}
              </div>

              {/* if no item in product */}
              {state?.searchQuery && items?.length === 0 && (
                <div className="cartmini__empty text-center mt-80">
                  <div className="mb-30">
                    <img
                      src={require("assets/img/shop/empty-cart.png")}
                      alt="empty-cart-img"
                      width={283}
                      height={171}
                    />
                  </div>
                  <h4>
                    Sorry! Could not find the product you were looking For!!!
                  </h4>
                  <p>Please wait until new products arrive!</p>
                  <button
                    onClick={() => navigate("/products")}
                    className="os-btn os-btn-3"
                  >
                    Continue Shopping
                  </button>
                </div>
              )}
              {isNewArrivalsPage ? (
                <></>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      height: "1px",
                      width: "100%",
                      backgroundColor: "black",
                    }}
                  ></div>
                </div>
              )}

              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-grid"
                  role="tabpanel"
                  aria-labelledby="pills-grid-tab"
                >
                  {itemsLoading ? (
                    <h4>Loading...</h4>
                  ) : (
                    <div className="row custom-row-10">
                      {isNewArrivalsPage === true
                        ? newItems?.map((item, index: React.Key) => (
                            <div
                              key={index}
                              className={
                                shop_col
                                  ? shop_col
                                  : "col-xl-4 col-lg-4 col-md-6 col-sm-6 custom-col-10"
                              }
                            >
                              <ProductItemTwoV2 item={item} />
                            </div>
                          ))
                        : filteredItems?.length > 0
                        ? filteredItems?.map((item: any, i: number) => (
                            <div
                              key={i}
                              className={
                                shop_col
                                  ? shop_col
                                  : "col-xl-4 col-lg-4 col-md-6 col-sm-6 custom-col-10"
                              }
                            >
                              <ProductItemTwoV2 item={item} />
                            </div>
                          ))
                        : currentItems?.map((item: any, i: number) => (
                            <div
                              key={i}
                              className={
                                shop_col
                                  ? shop_col
                                  : "col-xl-4 col-lg-4 col-md-6 col-sm-6 custom-col-10"
                              }
                            >
                              <ProductItemTwoV2 item={item} />
                            </div>
                          ))}
                    </div>
                  )}
                </div>
                {items?.length > 0 ? (
                  <div
                    className="tab-pane fade"
                    id="pills-list"
                    role="tabpanel"
                    aria-labelledby="pills-list-tab"
                  >
                    {filteredItems?.length > 0
                      ? filteredItems?.map((item: any, i: number) => (
                          <ProductListItemV2 key={i} item={item} />
                        ))
                      : currentItems?.map((item: any, i: number) => (
                          <ProductListItemV2 key={i} item={item} />
                        ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {items && (
                <div className="row mt-40">
                  <div className="col-xl-12">
                    <div className="shop-pagination-wrapper d-md-flex justify-content-between align-items-center">
                      <Pagination
                        pageCount={pageCount}
                        handlePageClick={handlePageClick}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* the new section  */}

          {shop_right && !shop_col && (
            <div className="col-xl-3 col-lg-3 col-md-4">
              {/* shop sidebar start */}
              <ShopSidebar
                feature_products={feature_products}
                brands={brands}
                colors={uniqueColors}
                sizes={uniqueSizes}
              />
              {/* shop sidebar end */}
            </div>
          )}
        </div>
      </div>

      <div className="bottom-section_shop">
        <div className="parent">
          <div
            className="left-section"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              flex: 0.5,
              alignItems: "start",
              backgroundColor: BOLTColors.productEndSectionBG,
            }}
          >
            <div className="text-section">
              <h1
                style={{
                  textTransform: "uppercase",
                  textAlign: "start",
                  color: "white",
                }}
              >
                Carry Easy <br /> Make Happy
              </h1>
              <p
                style={{
                  textAlign: "start",
                  color: "white",
                  marginTop: "10px",
                }}
              >
                A healthier you from the inside out. We've sourced the <br />{" "}
                cleanest ingredients to create a line of clean skin care
              </p>
            </div>
          </div>
          <div
            className="right-section"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flex: 0.5,
            }}
          >
            <img
              src={require(`assets/img/shop/banner/${
                isNewArrivalsPage ? "water" : "powerhouse"
              }.png`)}
              alt="empty-cart-img"
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShopArea
