import { Link } from "react-router-dom";
// internal
import { Item } from "redux/reducers/items.reducer";
import { SERVER_URL } from "helpers/helpers";

// img style
const imgStyle = {
  width: "100%",
  height: "100%",
};

const ProductItemTwoV2 = ({ item }: { item: Item }) => {
  return (
    <div className="product__item mb-40">
      <div className="product__wrapper">
        <div className="product__thumb">
          <div className="product__wrapper mb-60">
            <div className="product__thumb">
              <Link to={`/product-details/${item?._id}`}>
                <img
                  src={SERVER_URL + item?.mainImage}
                  alt="product-img"
                  width={255}
                  height={325}
                  style={imgStyle}
                />
                <img
                  className="product__thumb-2"
                  src={SERVER_URL + item?.hoverImage}
                  alt="product-img"
                  width={255}
                  height={325}
                  style={imgStyle}
                />
              </Link>

              {/* <div className="product__sale"></div> */}
            </div>
            <div className="product__content p-relative">
              <div className="product__content-inner">
                <h4>
                  <Link
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                    to={`/product-details/${item?._id}`}
                  >
                    {item?.title}
                  </Link>
                </h4>
                <div className="product__price transition-3">
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {item?.skuNumber}
                  </span>
                  {/* <span
                    style={{
                      color: "grey",
                      fontWeight: "normal",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                  >
                    {item?.category?.title}
                  </span> */}
                </div>
              </div>
            </div>
          </div>
          {/* // read more button  */}
          <Link
            to={`/product-details/${item?._id}`}
            style={{
              border: "1px solid black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <span style={{ color: "black" }}>Read More</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductItemTwoV2;
// import { Link } from "react-router-dom"
// // internal
// import { Item } from "redux/reducers/items.reducer"
// import { SERVER_URL } from "helpers/helpers"

// // img style
// const imgStyle = {
//   width: "100%",
//   height: "100%",
// }

// const ProductItemTwoV2 = ({ item }: { item: Item }) => {
//   return (
//     <div className="product__item mb-40">
//       <div className="product__wrapper">
//         <div className="product__thumb">
//           <div className="product__wrapper mb-60">
//             <div className="product__thumb">
//               <Link to={`/product-details/${item?._id}`}>
//                 <img
//                   src={SERVER_URL + item?.mainImage}
//                   alt="product-img"
//                   width={255}
//                   height={325}
//                   style={imgStyle}
//                 />
//                 <img
//                   className="product__thumb-2"
//                   src={SERVER_URL + item?.hoverImage}
//                   alt="product-img"
//                   width={255}
//                   height={325}
//                   style={imgStyle}
//                 />
//               </Link>

//               {/* <div className="product__sale"></div> */}
//             </div>
//             <div className="product__content p-relative">
//               <div className="product__content-inner">
//                 <h4>
//                   <Link
//                     style={{
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       maxWidth: "100%",
//                     }}
//                     to={`/product-details/${item?._id}`}
//                   >
//                     {item?.title}
//                   </Link>
//                 </h4>
//                 <div className="product__price transition-3">
//                   <span
//                     style={{
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       maxWidth: "100%",
//                     }}
//                   >
//                     {" "}
//                     {item?.skuNumber} -{" "}
//                   </span>
//                   <span
//                     style={{
//                       color: "grey",
//                       fontWeight: "normal",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       maxWidth: "100%",
//                     }}
//                   >
//                     {item?.category?.title}
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default ProductItemTwoV2
