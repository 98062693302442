import HeaderFour from "layout/headers/header-4";
import Wrapper from "layout/wrapper";
import { useEffect, useState } from "react";
import Breadcrumb from "components/common/breadcrumb";
import breadcrumb_bg from "assets/img/page-title/page-title-2.jpg";
import Footer from "layout/footers/footer";
import { useLocation, Location } from "react-router-dom";
import { useAppSelector } from "redux/hook";

const legalstate = {
  privacy: "privacy",
  terms: "terms",
};

const Legal = () => {
  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const { state = null } = useLocation() ?? "";
  const [legal, setLegal] = useState<string>("");

  useEffect(() => {
    if (state.legal === legalstate.privacy) {
      setLegal(settings[0]?.privacyPolicy);
    } else setLegal(settings[0]?.termsConditions);
  }, [state.legal]);

  const breadCrumbsTitle =
    state.legal === legalstate.privacy
      ? "Privacy Policy"
      : "Terms & Conditions";

  const legalBanner = settings[0]?.legalBanner;

  return (
    <Wrapper>
      <HeaderFour />
      <main>
        <Breadcrumb
          bannerImg={legalBanner}
          bg_img={breadcrumb_bg}
          title={breadCrumbsTitle}
          subtitle={breadCrumbsTitle}
        />

        <div
          style={{
            margin: "50px",
          }}
        >
          <p
            style={{ color: "black!" }}
            dangerouslySetInnerHTML={{
              __html: legal
                ? legal.replace(/\\r\\n/g, "")
                : settings[0]?.privacyPolicy.replace(/\\r\\n/g, ""),
            }}
          />
        </div>
      </main>

      <Footer />
    </Wrapper>
  );
};

export default Legal;
