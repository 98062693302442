import { Link } from "react-router-dom"
import SocialLinks from "./social-links"
import {
  SERVER_URL,
  handleCallClick,
  handleEmailClick,
  handleLocationClick,
} from "helpers/helpers"
import { useAppSelector } from "redux/hook"
import { Img } from "react-image"
import { BOLTColors } from "config/colors"

const footerWidget = [
  {
    id: 1,
    title: "quick links",
    footer__links: [
      { list: "Home", link: "/", state: false },
      { list: "About", link: "/about", state: false },
      { list: "Products", link: "/products", state: false },
      { list: "Contact Us", link: "/contact", state: false },
    ],
  },
  {
    id: 2,
    title: "Legal",
    footer__links: [
      { list: "Privacy Policy", link: "/legal", state: "privacy" },
      { list: "Terms & Conditions", link: "/legal", state: "terms" },
    ],
  },
]

const Footer = ({ style_2 }: { style_2?: boolean }) => {
  const contactInfo = useAppSelector((state) => state.address.contactInfo)
  const settings = useAppSelector((state) => state.setting.settingsInfo)
  const currentYear = new Date().getFullYear()
  return (
    <>
      <section
        style={{ backgroundColor: BOLTColors.footerBackgroundColorNavy }}
        className={`footer__area footer-bg ${style_2 ? "box-m-15" : ""}`}
      >
        <div className="footer__top pt-40 pb-40">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                <div className="footer__widget mb-30">
                  <div className="footer__widget-title mb-25">
                    <Link to="/">
                      <Img
                        loading="lazy"
                        height={50}
                        width={140}
                        src={SERVER_URL + settings[0]?.brandImg}
                        loader={<div className="spinner"></div>}
                      />
                    </Link>
                  </div>
                  <div className="footer__widget-content">
                    <p
                      style={{
                        fontSize: "15px",
                        color: "white",
                      }}
                    >
                      {" "}
                      {settings[0]?.aboutText &&
                        settings[0]?.aboutText.substring(0, 171)}
                      {settings[0]?.aboutText &&
                        settings[0]?.aboutText.length > 171 &&
                        "..."}
                    </p>
                    <div className="footer__contact">
                      <ul>
                        {/* <li>
                          <div className="icon">
                            <i
                              style={{ color: "black", fontSize: "large" }}
                              className="fal fa-map-marker-alt"
                            ></i>
                          </div>
                          <div
                            onClick={() =>
                              handleLocationClick(contactInfo[0]?.mapLink)
                            }
                            className="text cursor-pointer"
                          >
                            <span style={{ fontSize: "15px", color: "white" }}>
                              {contactInfo[0]?.addressInfo}
                            </span>
                          </div>
                        </li> */}
                        <li>
                          <div className="icon">
                            <i
                              style={{ color: "black", fontSize: "large" }}
                              className="fal fa-envelope-open-text"
                            ></i>
                          </div>
                          <div
                            onClick={() =>
                              handleEmailClick(contactInfo[0]?.email)
                            }
                            className="text cursor-pointer"
                          >
                            <span style={{ fontSize: "15px", color: "white" }}>
                              {contactInfo[0]?.email}
                            </span>
                          </div>
                        </li>
                        {/* <li>
                          <div className="icon">
                            <i
                              style={{ color: "black", fontSize: "large" }}
                              className="fal fa-phone-alt"
                            ></i>
                          </div>
                          <div
                            onClick={() =>
                              handleCallClick(contactInfo[0]?.phoneNumber)
                            }
                            className="text cursor-pointer"
                          >
                            <span style={{ fontSize: "15px", color: "white" }}>
                              +{contactInfo[0]?.phoneNumber}
                            </span>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {footerWidget.map((item) => (
                <div
                  key={item.id}
                  className="col-xl-2 col-lg-2 col-md-3 col-12"
                >
                  <div className="footer__widget mb-30">
                    <div
                      className={`footer__widget-title ${
                        item.id === 2 ? "mb-25" : ""
                      }`}
                    >
                      <h5 style={{ color: "white" }}>{item.title}</h5>
                    </div>
                    <div className="footer__widget-content">
                      <div className="footer__links">
                        <ul>
                          {item.footer__links.map((link, index) => (
                            <li key={index}>
                              <Link
                                state={
                                  link.state ? { legal: link.state } : undefined
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "white",
                                  fontSize: "14px",
                                }}
                                to={link.link}
                              >
                                {link.list}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-12 col-xl-3 col-lg-3 col-md-2 mt-50">
                <p style={{ color: "white" }}>
                  Feel Free to get in touch with us via phone or{" "}
                  <span
                    onClick={() => handleEmailClick(contactInfo[0]?.email)}
                    style={{ cursor: "pointer" }}
                  >
                    send us a message
                  </span>
                </p>
                {/* <p
                  onClick={() => handleCallClick(contactInfo[0]?.phoneNumber)}
                  style={{ color: "white", cursor: "pointer" }}
                >
                  {contactInfo[0]?.phoneNumber}
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div style={{ borderTopColor: "#818f8f" }} className="footer__bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-7">
                <div className="footer__copyright">
                  <p style={{ fontSize: "15px", color: "white" }}>
                    Copyright {currentYear} ©{" "}
                    <Link style={{ color: "white" }} to="/">
                      BOLT Products{" "}
                    </Link>
                    All Rights Reserved. Developed by{" "}
                    <a
                      href={"https://www.compu-vision.me"}
                      target="_blank"
                      style={{ color: "white" }}
                      rel="noopener noreferrer"
                    >
                      COMPU-VISION.
                    </a>
                  </p>
                </div>
              </div>
              {/* <div className="col-xl-4 col-lg-5">
                <div className="footer__social f-right">
                  <ul>
                    <SocialLinks />
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
