// import type { Error, LandingPath } from "../types";
export const BASE_URL: string = "https://api.boltproducts.net/api/";
export const SERVER_URL: string =
  "https://api.boltproducts.net/public/uploads/";
export const isDevelopmentMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const printData = ({ name, data, type }: any) => {
  if (isDevelopmentMode) {
    if (type == "error") {
      console.error({ [name]: data });
    } else {
      console.log({ [name]: data });
    }
  } else {
    return;
  }
};

type LandingPath = {
  slide: string;
  category: string;
  setting: string;
  items: string;
  contact: string;
  address: string;
  items_by_category: string;
};

export const HOME_PATH: LandingPath = {
  slide: "slide",
  category: "category",
  setting: "setting",
  items: "items",
  contact: "contact-us",
  address: "address",
  items_by_category: "items-by-category",
};

export const handleCallClick = (phoneNumber: {}) => {
  if (phoneNumber) {
    window.open(`tel:${phoneNumber}`, "_blank", "noopener,noreferrer");
  }
};

export const handleOpenLink = (link: string | URL) => {
  if (link) {
    window.open(link, "_blank", "noopener,noreferrer");
  }
};

export const handleLocationClick = (mapsUrl: string) => {
  if (mapsUrl) {
    window.open(mapsUrl, "_blank");
  }
};

export const handleWhatsappClick = (phoneNumber: {}) => {
  if (phoneNumber) {
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}`,
      "_blank",
      "noopener,noreferrer"
    );
  }
};

export const handleEmailClick = (email: {}) => {
  if (email) {
    window.open(
      `mailto:${email}?subject=Subject&body=Hello`,
      "_blank",
      "noopener,noreferrer"
    );
  }
};
