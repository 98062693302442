import { BOLTColors } from "config/colors";
import { SERVER_URL } from "helpers/helpers";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

type ProductItemColProps = {
  idx: number;
  _id: string;
  mainImage: string;
  hoverImage: string;
  title: string;
  skuNumber: string | number;
};

const imgStyle: CSSProperties = {
  width: "100%",
  height: "100%",
};

const titleStyle: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  textAlign: "center",
  fontWeight: "bold",
  color: "black",
};

const priceStyle: CSSProperties = {
  maxWidth: "100%",
  color: BOLTColors.black,
  fontWeight: "bold",
  fontSize: "22px",
  marginTop: "8px",
  backgroundColor: BOLTColors.lightSalmon,
  padding: "10px",
  borderRadius: "5px",
};

const skuStyle: CSSProperties = {
  maxWidth: "100%",
  color: BOLTColors.darkerOrange,
  fontStyle: "italic",
};

const commonCenter: CSSProperties = {
  display: "flex",
  justifyContent: "center",
};

export default function ProductItemCol({
  idx,
  _id,
  mainImage,
  hoverImage,
  title,
  skuNumber,
}: ProductItemColProps) {
  return (
    <div
      key={idx}
      style={{ overflow: "hidden" }}
      className="col-xl-2.4 col-lg-3 col-md-4 product__item"
    >
      <div className="product__wrapper mb-20">
        <div className="product__thumb">
          <Link to={`/product-details/${_id}`}>
            <img
              src={SERVER_URL + mainImage}
              alt="product-img"
              width={255}
              height={325}
              style={imgStyle}
            />
            <img
              className="product__thumb-2"
              src={SERVER_URL + hoverImage}
              alt="product-img"
              width={255}
              height={325}
              style={imgStyle}
            />
          </Link>
        </div>
        <div className="product__content p-relative">
          <div className="product__content-inner">
            <div style={commonCenter}>
              <h4>
                <Link style={titleStyle} to={`/product-details/${_id}`}>
                  {title}
                </Link>
              </h4>
            </div>
            <div style={commonCenter}>
              <span style={skuStyle}>{skuNumber}</span>
            </div>
            {/* <div style={commonCenter}>
              <span style={priceStyle}>$ 140</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
