import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import menuDataPord from "data/menu-data-prod";
import background from "assets/img/bg/mega-menu-bg.jpg";
import { Link as ReactScrollTo } from "react-scroll";
import "./nav-manus-prod.css";
import { BOLTColors } from "config/colors";

const NavManusProd = () => {
  const [hover, setHover] = useState<string | number>("");

  const getLinkStyles = ({
    isActive,
    hover,
    index,
  }: {
    isActive: boolean;
    hover: string | number;
    index: number;
  }) => {
    return {
      cursor: "pointer",
      color: isActive
        ? BOLTColors.orange
        : hover === index
        ? BOLTColors.orange
        : "black",
      textDecorationLine: isActive ? "underline" : "none",
      textUnderlineOffset: isActive ? 25 : undefined,
      textDecorationThickness: 3,
      textDecorationColor: BOLTColors.darkerOrange,
      fontWeight: "bolder",
    };
  };

  return (
    <nav>
      <ul>
        {menuDataPord?.map((item: any, index: any) => {
          return (
            <li
              key={index}
              className={`${
                item.hasDropdown && !item.megamenu
                  ? "active has-dropdown"
                  : item.megamenu && "mega-menu has-dropdown"
              }`}
            >
              {/* this is the used nav */}
              <NavLink
                onMouseEnter={() => {
                  setHover(index);
                }}
                onMouseLeave={() => {
                  setHover("");
                }}
                style={({ isActive }) =>
                  getLinkStyles({ isActive, hover, index })
                }
                to={item.link}
              >
                {`${item.title}`}
              </NavLink>

              {item?.hasDropdown && !item.megamenu && (
                <ul className="submenu transition-3">
                  {item?.dropdownItems?.map((menu: any, index: any) => (
                    <li key={index}>
                      <NavLink style={{ cursor: "pointer" }} to={`${menu.ref}`}>
                        {menu.title}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}

              {item.hasDropdown && item.megamenu && (
                <ul
                  className="submenu transition-3"
                  style={{
                    background: background,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center right",
                    backgroundSize: "cover",
                  }}
                >
                  {item.dropdownItems &&
                    item.dropdownItems.map((menu: any, index: number) => (
                      <li
                        key={index}
                        className={`${menu.dropdownMenu ? "has-dropdown" : ""}`}
                      >
                        <ReactScrollTo
                          style={{ cursor: "pointer" }}
                          to={`${menu.ref}`}
                        >
                          {menu.title}
                        </ReactScrollTo>
                        {/* <ul>
                          {menu?.dropdownMenu?.map((m: any, index: any) => (
                            <li key={index}>
                              <ReactScrollTo
                                style={{ cursor: "pointer" }}
                                to={`${m.ref}`}
                              >
                                {m.title}
                              </ReactScrollTo>
                            </li>
                          ))}
                        </ul> */}
                      </li>
                    ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavManusProd;
