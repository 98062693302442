// internal
import Wrapper from "layout/wrapper";
import HeaderThree from "layout/headers/header-3";
import ShopCategory from "components/shop/shop-category";
import HeroSliderOne from "components/hero-banner/hero-banner-one";
import BlogArea from "components/blogs/blog-area";
import SubscribeArea from "components/subscribe-area";
import TrendingProductsThree from "components/products/trending-products-3";
import BannerProducts from "components/products/banner-products";
import SaleOffProducts from "components/products/sale-off-products";
import Footer from "layout/footers/footer";
import { useEffect, useState } from "react";
import { IProduct } from "types/product-d-t";
import { jsonData } from "./product-data";
export function HomePageSix() {
  const [products, setProducts] = useState<any>(jsonData);
  console.log(products);
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const response = await fetch("./product-data.json"); // Adjust the URL as needed
  //       if (response.ok) {
  //         const file = await response.text();
  //         const parsedData = JSON.parse(file);
  //         console.log(parsedData);

  //         setProducts(parsedData);
  //       } else {
  //         console.error("Failed to fetch data");
  //       }
  //     } catch (error) {
  //       return console.error("Error fetching data:", error);
  //     }
  //   }

  //   fetchData();
  // }, []);
  // const file = await fs.readFile(
  //   process.cwd() + "/src/app/product-data.json",
  //   "utf8"
  // );

  return (
    <Wrapper>
      {/* header start */}
      <HeaderThree />
      {/* header end */}

      <main>
        {/* hero banner start */}
        <HeroSliderOne style_2={true} />
        {/* hero banner end */}

        {/* category area start */}
        <ShopCategory spacing="pt-30" style_4={true} />
        {/* category area end */}

        {/* trending products start */}
        <TrendingProductsThree products={products} style_2={true} />
        {/* trending products end */}

        {/* product banner start */}
        <BannerProducts products={products} />
        {/* product banner end */}

        {/* sale of products start */}
        <SaleOffProducts products={products} />
        {/* sale of products end */}

        {/* blog area start */}
        <BlogArea />
        {/* blog area end */}

        {/* subscribe area start */}
        <SubscribeArea />
        {/* subscribe area end */}
      </main>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Wrapper>
  );
}
