import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hook";
import { SERVER_URL } from "helpers/helpers";
import "swiper/css";
import { useEffect, useRef, useState } from "react";

type IProps = {
  spacing?: string;
  style_2?: boolean;
  style_3?: boolean;
  style_4?: boolean;
  category_2?: boolean;
};

const ShopCategory = ({
  spacing = "",
  style_2,
  style_3,
  style_4,
  category_2 = false,
}: IProps) => {
  const categoryData =
    useAppSelector((state) => state.category.categoryData) ?? [];
  const sliderRef = useRef<Slider | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles = !isMobile
    ? {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {};

  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: isMobile ? 1 : 1,
  };

  return (
    <section className="slider__area slider__area-3 p-relative">
      <button
        onClick={() => sliderRef.current?.slickPrev()}
        className="slick-prev slick-arrow"
      >
        <i className="fal fa-angle-left"></i>
      </button>
      <Slider ref={sliderRef} className="slider-active-3" {...settings}>
        {categoryData.map((banner, index) => (
          <div key={index}>
            <div style={styles}>
              <div
                className="single-slider single-slider-2 slider__height-6 d-flex align-items-center"
                style={{
                  backgroundImage: `url(${SERVER_URL + banner?.categoryImage})`,
                  objectFit: "contain",
                  width: isMobile ? "100vw" : "60vw",
                  height: isMobile ? "100vw" : "20vw",
                }}
              >
                <div
                  className="container custom-container-2"
                  style={{
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    padding: "10px",
                    marginLeft: "5%",
                    marginRight: "5%",
                  }}
                >
                  <div className="row">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h5
                        style={{
                          color: "black",
                          textAlign: "center",
                          alignItems: "center",
                        }}
                      >
                        {banner?.title}
                      </h5>
                      <Link
                        style={{ width: "200px", alignSelf: "center" }}
                        state={{ searchQuery: banner._id, from: "categories" }}
                        to="/product-category"
                        className="os-btn"
                      >
                        Shop now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <button
        onClick={() => sliderRef.current?.slickNext()}
        className="slick-next slick-arrow"
      >
        <i className="fal fa-angle-right"></i>
      </button>
    </section>
  );
};

export default ShopCategory;

// old

// // import Link from "next/link";
// // import Image from "next/image";
// import { Link } from "react-router-dom";
// // internal
// import category_data from "data/category-data";
// import { Element } from "react-scroll";
// import { useAppSelector } from "redux/hook";
// import { SERVER_URL } from "helpers/helpers";

// // type
// type IProps = {
//   spacing?: string;
//   style_2?: boolean;
//   style_3?: boolean;
//   style_4?: boolean;
//   category_2?: boolean;
// };

// const ShopCategory = ({
//   spacing = "",
//   style_2,
//   style_3,
//   style_4,
//   category_2 = false,
// }: IProps) => {
//   const categoryData =
//     useAppSelector((state) => state.category.categoryData) ?? [];
//   return (
//     <>
//       {category_2 && (
//         <div className="banner__area-df mt-10">
//           {categoryData.map((banner) => (
//             <div key={banner._id} className="banner__item-3 mb-30">
//               <div
//                 className="single-slider single-slider-2 slider__height-4 d-flex align-items-center"
//                 style={{
//                   backgroundImage: `url(${SERVER_URL + banner.categoryImage!})`,
//                 }}
//               >
//                 <div
//                   style={{
//                     backdropFilter: "blur(5px)",
//                     backgroundColor: "rgba(255, 255, 255, 0.6)", // Set the color with some transparency
//                     padding: "8px",
//                     borderRadius: "5px",
//                   }}
//                   className="banner__content-5"
//                 >
//                   <h5 style={{ color: "black" }}>{banner.title}</h5>
//                   {/* <p style={{ color: "black" }}>{banner.smDesc}</p> */}
//                   <Link
//                     // style={{ color: "black" }}
//                     state={{ searchQuery: banner._id, from: "categories" }}
//                     to="/product-category"
//                     className="os-btn"
//                   >
//                     Shop Now
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}

//       {!category_2 && (
//         <div className={`banner__area ${spacing}`}>
//           <div className={`container ${style_3 ? "custom-container" : ""}`}>
//             <div
//               className={`${
//                 !style_2 && !style_3 && !style_4
//                   ? "banner__inner p-relative mt--95 z-index-1"
//                   : ""
//               } ${style_4 ? "banner__inner-2 p-relative" : ""}`}
//             >
//               <div className="row">
//                 {category_data.slice(0, 3).map((item, index) => (
//                   <div key={index} className="col-xl-4 col-lg-4 col-md-6">
//                     <div className="banner__item mb-30 p-relative">
//                       <div className="banner__thumb fix">
//                         <Link
//                           style={{ border: "0px solid white" }}
//                           to={`/shop`}
//                           className="w-img"
//                         >
//                           <img
//                             src={item.img}
//                             style={{
//                               border: "0px solid white",
//                             }}
//                             alt="banner"
//                             width={356}
//                             height={219}
//                           />
//                         </Link>
//                       </div>
//                       <div className="banner__content p-absolute transition-3">
//                         <h5>
//                           <Link
//                             to={`/shop`}
//                             dangerouslySetInnerHTML={{
//                               __html: item.parentTitle,
//                             }}
//                           ></Link>
//                         </h5>
//                         <Link to={`/shop`} className="link-btn">
//                           Discover now
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ShopCategory;
