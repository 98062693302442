import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hook";
import "./trendingProd2.css";
import { SERVER_URL } from "helpers/helpers";
import { CSSProperties } from "react";
import { BOLTColors } from "config/colors";

const imgStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  padding: "10px",
};

const titleStyle: CSSProperties = {
  textTransform: "uppercase",
  fontWeight: "lighter",
  fontSize: "20px",
};

const subTitleStyle: CSSProperties = {
  fontWeight: "bold",
  textTransform: "uppercase",
};

const bottomButtonStyle: CSSProperties = {
  cursor: "pointer",
  border: "1px solid " + BOLTColors.textBorder,
  textAlign: "center",
  width: "220px",
  padding: "13px",
  borderRadius: "5px",
  color: BOLTColors.darkText,
  fontWeight: 500,
  fontSize: "14px",
  textTransform: "capitalize",
};

const bigProductTitleStyle: CSSProperties = {
  padding: "15px",
  backdropFilter: "blur(10px)", // Adjust the blur strength as needed
  backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust the opacity as needed
};

const bigProductTitleSpanStyle: CSSProperties = {
  fontSize: "larger",
  fontWeight: "bold",
};

const discoverNowLinkStyle: CSSProperties = {
  fontSize: "larger",
};

const flexAllEnd: CSSProperties = {
  justifyContent: "end",
  alignContent: "end",
  justifyItems: "end",
  alignItems: "end",
  display: "flex",
};

const priceOrange: CSSProperties = {
  color: BOLTColors.darkerOrange,
  fontWeight: 600,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  backgroundColor: "white",
  paddingLeft: "8px",
  paddingRight: "8px",
  paddingTop: "5px",
  paddingBottom: "5px",
  borderRadius: "15px",
  textAlign: "center",
  borderEndEndRadius: "20px",
  borderEndStartRadius: "20px",
  borderStartStartRadius: "20px",
  borderStartEndRadius: "20px",
};

const flexRowAlign: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  minHeight: "150px",
  alignItems: "center",
};

const TrendingProductsTwo = ({
  hasDarkBackground = true,
  title = "STRAIGHT FROM BOLT-TEC",
  sub_title = "NEW PRODUCTS",
}) => {
  const items = useAppSelector((state) => state.items.itemData) ?? [];
  const featuredItems = items?.filter((item) => item?.featured == true);
  const highlightedItem = featuredItems?.find((item) => {
    return item.isHighlighted == true || item.isHighlighted == "true";
  });

  return (
    <section className="product__area pt-60 pb-100 pl-40 pr-40">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="section__title-wrapper text-center mb-55">
              <div className="mb-10 mt-10">
                <h5 style={titleStyle}>{title}</h5>
                <h2 style={subTitleStyle}>{sub_title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="product__banner p-relative" style={flexAllEnd}>
          <div
            className="product__banner-inner p-absolute fix d-none d-lg-block"
            style={{ right: 0, background: BOLTColors.productItembg }}
          >
            <div className="product__banner-img fix">
              <Link to={`/product-details/${highlightedItem?._id}`}>
                <img
                  src={SERVER_URL + highlightedItem?.mainImage}
                  alt="product-banner"
                  width={905}
                  height={900}
                />
              </Link>
            </div>
            <div className="big_product_title" style={bigProductTitleStyle}>
              <h4>
                <Link to={`/product-details/${highlightedItem?._id}`}>
                  <span style={bigProductTitleSpanStyle}>
                    {highlightedItem?.title}{" "}
                  </span>
                </Link>
              </h4>
              <Link
                to={`/product-details/${highlightedItem?._id}`}
                className="link-btn"
              >
                <span style={discoverNowLinkStyle}>discover now</span>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-lg-6">
              <div className="row">
                {featuredItems?.slice(0, 4).map((item, idx) => (
                  <div
                    key={idx}
                    style={{ overflow: "hidden" }}
                    className="col-lg-6 col-md-6 col-sm-12 product__item"
                  >
                    <div
                      style={{
                        background: BOLTColors.productItembg,
                        marginBottom: idx <= 1 ? "10px" : undefined,
                      }}
                      className="product__wrapper"
                    >
                      <div className="product__thumb">
                        <Link to={`/product-details/${item?._id}`}>
                          <img
                            src={SERVER_URL + item?.mainImage}
                            alt="product-img"
                            width={255}
                            height={325}
                            style={imgStyle}
                          />
                          <img
                            className="product__thumb-2"
                            src={SERVER_URL + item?.hoverImage}
                            alt="product-img"
                            width={255}
                            height={325}
                            style={imgStyle}
                          />
                        </Link>
                      </div>
                      <div style={flexRowAlign}>
                        <h4
                          style={{
                            width: "80%",
                            padding: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          <Link to={`/product-details/${item?._id}`}>
                            {item?.title}
                          </Link>
                        </h4>
                        <span style={priceOrange}>{item?.skuNumber}</span>
                        {/* <span style={priceOrange}>$150</span> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
      >
        <Link to={"/products"}>
          <p style={bottomButtonStyle}>Explore other products</p>
        </Link>
      </div>
    </section>
  );
};

export default TrendingProductsTwo;
