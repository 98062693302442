"use client";
import React, { CSSProperties } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorMsg from "../common/error-msg";
import { useAppDispatch, useAppSelector } from "redux/hook";
import { sendContactMessage } from "redux/actions/contact.action";

type FormData = {
  name: string;
  email: string;
  subject: string;
  message: string;
};

const schema = yup.object().shape({
  name: yup.string().required().label("Name"),
  email: yup.string().required().email().label("Email"),
  subject: yup.string().required().min(10).label("Subject"),
  message: yup.string().required().min(10).label("Message"),
});

const ContactForm = () => {
  const sendingMessage = useAppSelector((state) => state.contact.loading);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const onSubmit = handleSubmit((data) => {
    dispatch(
      sendContactMessage({
        email: JSON.stringify(data.email),
        subject: JSON.stringify(data.subject),
        message: JSON.stringify(data.message),
        name: JSON.stringify(data.name),
      })
    ).then((resultAction) => {
      if (sendContactMessage.fulfilled.match(resultAction)) {
        reset();
      }
    });
  });

  const inputStyle: CSSProperties = {
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    color: "gray",
  };
  return (
    <>
      <form onSubmit={onSubmit} id="contact-form">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="contact__input">
              <div className="input-container_contact">
                <span className="adornment_contact">
                  <i style={{ color: "#8a8a8a" }} className="fa fa-user"></i>
                </span>
                <input
                  className="input_contact"
                  id="name"
                  {...register("name")}
                  placeholder="Your Name"
                  type="text"
                  style={inputStyle}
                />
              </div>

              <ErrorMsg msg={errors.name?.message!} />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="contact__input">
              <div className="input-container_contact">
                <span className="adornment_contact">
                  <i
                    style={{ color: "#8a8a8a" }}
                    className="fa fa-envelope-open-text"
                  ></i>
                </span>
                <input
                  className="input_contact"
                  id="email"
                  {...register("email")}
                  type="email"
                  placeholder="Your Email"
                  style={inputStyle}
                />
              </div>
              <ErrorMsg msg={errors.email?.message!} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="contact__input">
              <div className="input-container_contact">
                <span className="adornment_contact">
                  <i style={{ color: "#8a8a8a" }} className="fa fa-info"></i>
                </span>
                <input
                  className="input_contact"
                  style={inputStyle}
                  id="subject"
                  {...register("subject")}
                  type="text"
                  placeholder="Your Subject"
                />
              </div>
              <ErrorMsg msg={errors.subject?.message!} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div className="contact__input">
              <div className="input-container_contact">
                <span className="adornment_contact_area">
                  <i style={{ color: "#8a8a8a" }} className="fa fa-pen"></i>
                </span>
                <textarea
                  className="input_contact"
                  style={{ padding: 10, ...inputStyle }}
                  id="message"
                  {...register("message")}
                  cols={30}
                  placeholder="Your Message"
                  rows={10}
                ></textarea>
              </div>
              <ErrorMsg isLast msg={errors.message?.message!} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            <div
              className="contact__submit"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <button className="submit-button" type="submit">
                {sendingMessage ? "Sending" : "SUBMIT"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
