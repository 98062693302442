// import Link from "next/link";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import { StaticImageData } from "next/image";
import default_bg from "assets/img/page-title/page-title-1.jpg";
import { useAppSelector } from "redux/hook";
import { SERVER_URL } from "helpers/helpers";

// prop type
type IProps = {
  bg_img?: any;
  title: string;
  mid_title?: string;
  subtitle: string;
  bannerImg: string;
  isProductsPage?: boolean;
  mobileBackgroundStyles?: {};
};

const Breadcrumb = ({
  bg_img = default_bg,
  title,
  subtitle,
  mid_title = "",
  bannerImg,
  isProductsPage = false,
  mobileBackgroundStyles,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the screen width as needed for mobile
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const bannerStyle = isMobile
    ? settings
      ? {
          background: `url(${SERVER_URL + bannerImg})`,
          ...mobileBackgroundStyles,
        }
      : {}
    : {
        background: `url(${SERVER_URL + bannerImg})`,
      };

  return (
    <>
      <section
        className="page__title p-relative d-flex align-items-center"
        style={bannerStyle}
      >
        <div className="container">
          {!mid_title && !subtitle ? (
            <></>
          ) : (
            <div className="row">
              <div
                style={{
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
                className="col-xl-12"
              >
                <div
                  style={{
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                    padding: "18px",
                    borderRadius: "15px",
                    width: "65%",
                    alignSelf: "center",
                  }}
                  className="page__title-inner text-center"
                >
                  <h1 style={{ fontSize: "30px" }}>{title}</h1>
                  {!isProductsPage && (
                    <div className="page__title-breadcrumb">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          {mid_title ? (
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              <Link to={`/${mid_title.toLowerCase()}`}>
                                {mid_title}
                              </Link>
                            </li>
                          ) : null}

                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {subtitle}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
