import Header from "layout/headers/header";
import HeaderFour from "layout/headers/header-4";
import { Link } from "react-router-dom";

type Props = {
  text: string;
};

const P404 = ({ text = "" }: Props) => {
  return (
    <>
      <HeaderFour />
      <div>
        <h2
          style={{
            textAlign: "center",
            marginTop: "50vh",
            textDecorationLine: "underline",
            textDecorationColor: "black",
            fontWeight: "bold",
          }}
        >
          {text ?? ""}
        </h2>
        <Link to={"/"}>
          <h4
            style={{
              fontWeight: "bold",
              textAlign: "center",
              textDecorationLine: "underline",
            }}
          >
            Go Back To Homepage
          </h4>
        </Link>
      </div>
    </>
  );
};

export default P404;
