import Wrapper from "layout/wrapper";
import Breadcrumb from "components/common/breadcrumb";
import breadcrumb_bg from "assets/img/page-title/page-title-2.jpg";
import ContactArea from "components/contact/contact-area";
import Footer from "layout/footers/footer";
import HeaderFour from "layout/headers/header-4";
import { useAppSelector } from "redux/hook";
import { useEffect, useState } from "react";
import { BOLTColors } from "config/colors";

export default function ContactPageV2() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { contactInfo, loading } = useAppSelector((state) => ({
    contactInfo: state.address.contactInfo,
    loading: state.address.loading,
  }));

  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const contactBanner = settings[0]?.contactBanner;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the screen width as needed for mobile
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileBackgroundStyles = isMobile
    ? {
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }
    : {};

  return (
    <Wrapper>
      {/* header start */}
      <HeaderFour />
      {/* header end */}

      <main>
        {/* breadcrumb start */}
        {/* <Breadcrumb
          mobileBackgroundStyles={mobileBackgroundStyles}
          bannerImg={contactBanner}
          bg_img={breadcrumb_bg}
          title="Contact Us"
          subtitle="Contact"
        /> */}
        {/* breadcrumb end */}
        <section
          style={{ backgroundColor: BOLTColors.lightBlue }}
          className="sky-parent"
        >
          <div className="mt-100">
            <h1
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "75px",
              }}
            >
              Get in Touch
            </h1>
            <p
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "28px",
                fontWeight: 100,
                lineHeight: "45px",
                letterSpacing: "1.5px",
              }}
            >
              Want to get in touch ? We'd love to hear from you. <br /> Here's
              how you can reach us......
            </p>
          </div>
        </section>

        {/* contact area start */}
        <ContactArea />
        {/* contact area end */}

        {/* contact map start */}
        {/* <section className="contact__map">
          <div className="container-fluid p-0">
            <div className="row g-0">
              <div className="col-xl-12">
                <div className="contact__map-wrapper p-relative">
                  <iframe
                    src={
                      loading
                        ? "https://maps.google.com/maps?q=Qatar&output=embed"
                        : contactInfo[0]?.mapLink
                    }
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <h3
          style={{
            textAlign: "center",
            color: "gray",
            marginTop: "50px",
            marginBottom: "50px",
          }}
        >
          BOLT PRODUCTS | QATAR
        </h3>
        {/* contact map end */}
      </main>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Wrapper>
  );
}
