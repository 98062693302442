import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import Wrapper from "layout/wrapper";
import HeaderFour from "layout/headers/header-4";
import Breadcrumb from "components/common/breadcrumb";
import Footer from "layout/footers/footer";
import ProductDetailsUpper from "components/product-details/product-details-upper";
import ProductDetailsBottom from "components/product-details/product-details-bottom";
import RelatedProducts from "components/products/related-products";
import { fetchSingleItemByID } from "redux/actions/items.action";
import { useAppDispatch, useAppSelector } from "redux/hook";
import ProductDetailsUpperV2 from "components/product-details/prod-det-upper-v2";
import RelatedProductsV2 from "components/products/related-products-v2";

import "./page.css";
import ProductDetailsNewDesign from "components/product-details/product-details-new-design";

export default function ProductDetailsPageID() {
  const params: any = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSingleItemByID({ _id: params.id }));
  }, [params.id]);

  const items = useAppSelector((state) => state.items.itemData);
  const oneItem = useAppSelector((state) => state.items.oneItem);
  const oneItemLoading = useAppSelector(
    (state) => state?.items?.oneItemLoading
  );

  const uniqueItems = items
    ?.filter((item) => item?._id != params?.id)
    .slice(0, 4);

  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const productBanner = settings[0]?.productsBanner;

  return (
    <Wrapper>
      <HeaderFour />
      <main>
        <section className="shop__area pb-65 pt-100">
          <div className="shop__top grey-bg-6 pt-100 pb-90">
            <div className="container">
              {oneItemLoading ? (
                <div
                  style={{
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h5 style={{ marginRight: "15px", marginTop: "10px" }}>
                    Bringing your next favorite item to the spotlight...
                  </h5>
                  <div className="spinner"></div>
                </div>
              ) : (
                <ProductDetailsNewDesign item={oneItem} />
              )}
            </div>
          </div>
        </section>

        <RelatedProductsV2 uniqueItems={uniqueItems} />
      </main>

      <Footer />
    </Wrapper>
  );
}

// OLD DESIGN
// import { useParams } from "react-router-dom";
// import React, { useEffect } from "react";
// import Wrapper from "layout/wrapper";
// import HeaderFour from "layout/headers/header-4";
// import Breadcrumb from "components/common/breadcrumb";
// import Footer from "layout/footers/footer";
// import ProductDetailsUpper from "components/product-details/product-details-upper";
// import ProductDetailsBottom from "components/product-details/product-details-bottom";
// import RelatedProducts from "components/products/related-products";
// import { fetchSingleItemByID } from "redux/actions/items.action";
// import { useAppDispatch, useAppSelector } from "redux/hook";
// import ProductDetailsUpperV2 from "components/product-details/prod-det-upper-v2";
// import RelatedProductsV2 from "components/products/related-products-v2";

// import "./page.css";

// export default function ProductDetailsPageID() {
//   const params: any = useParams();
//   const dispatch = useAppDispatch();

//   useEffect(() => {
//     dispatch(fetchSingleItemByID({ _id: params.id }));
//   }, [params.id]);

//   const items = useAppSelector((state) => state.items.itemData);
//   const oneItem = useAppSelector((state) => state.items.oneItem);
//   const oneItemLoading = useAppSelector(
//     (state) => state?.items?.oneItemLoading
//   );

//   const uniqueItems = items
//     ?.filter((item) => item?._id != params?.id)
//     .slice(0, 4);

//   const settings = useAppSelector((state) => state.setting.settingsInfo);
//   const productBanner = settings[0]?.productsBanner;

//   return (
//     <Wrapper>
//       {/* header start */}
//       <HeaderFour />
//       {/* header end */}

//       <main>
//         {/* breadcrumb start */}
//         <Breadcrumb
//           bannerImg={productBanner}
//           title={oneItem?.items?.title}
//           mid_title="Products"
//           subtitle={oneItem?.items?.title}
//         />
//         {/* breadcrumb end */}

//         {/* shop details upper area start */}
//         <section className="shop__area pb-65">
//           <div className="shop__top grey-bg-6 pt-100 pb-90">
//             <div className="container">
//               {oneItemLoading ? (
//                 <div
//                   style={{
//                     alignSelf: "center",
//                     display: "flex",
//                     flexDirection: "row",
//                     justifyContent: "center",
//                     alignItems: "center",
//                   }}
//                 >
//                   <h5 style={{ marginRight: "15px", marginTop: "10px" }}>
//                     Bringing your next favorite item to the spotlight...
//                   </h5>
//                   <div className="spinner"></div>
//                 </div>
//               ) : (
//                 <ProductDetailsUpperV2 item={oneItem} />
//               )}

//               {/* <ProductDetailsBottom product={product} /> dont want this*/}
//             </div>
//           </div>
//         </section>
//         {/* shop details upper area end */}

//         {/* related products start */}
//         <RelatedProductsV2 uniqueItems={uniqueItems} />
//         {/* related products end */}
//       </main>

//       {/* footer start */}
//       <Footer />
//       {/* footer end */}
//     </Wrapper>
//   );
// }
