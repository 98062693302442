// import { promises as fs } from "fs";
// import React from "react";
// import { Metadata } from "next";
import Wrapper from "layout/wrapper"
import Header from "layout/headers/header"
import Breadcrumb from "components/common/breadcrumb"
import Footer from "layout/footers/footer"
import ShopArea from "components/shop/shop-area"
import { jsonData as product_data } from "app/home-6/product-data"
import HeaderFour from "layout/headers/header-4"
import { useAppSelector } from "redux/hook"

// export const metadata: Metadata = {
//   title: "Shop Page",
// };

export default function ShopPage() {
  //   const file = await fs.readFile(
  //     process.cwd() + "/src/app/product-data.json",
  //     "utf8"
  //   );
  //   const product_data = JSON.parse(file);
  const settings = useAppSelector((state) => state.setting.settingsInfo)
  const productsBanner = settings[0]?.productsBanner
  return (
    <Wrapper>
      {/* header start */}
      <HeaderFour />
      {/* header end */}

      <main>
        {/* breadcrumb start */}
        <Breadcrumb bannerImg={productsBanner} title="Shop" subtitle="Shop" />
        {/* breadcrumb end */}

        {/* shop area start */}
        <ShopArea product_data={product_data} />
        {/* shop area end */}
      </main>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Wrapper>
  )
}
