import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOME_PATH, printData } from "helpers/helpers";

export const fetchSlideShow = createAsyncThunk("slide/fetchSlide", async () => {
  try {
    const response = await axios.get(HOME_PATH.slide);
    return response.data;
  } catch (error) {
    printData({ name: "slide", data: error, type: "error" });
  }
});
