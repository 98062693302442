export const BOLTColors = {
  orange: "#ff5d17",
  darkerOrange: "#ff4e05",
  black: "black",
  lightSalmon: "#f9e9e2",
  textBorder: "#868686",
  darkText: "#302f2f",
  sectionSilverBg: "#f8f8f8",
  arrowBackground: "#a4a4a4",
  productItembg: "#f5f5f5",
  supportLightBlueBG: "#e9faff",
  supportIconsFillGray: "#3f4445",
  footerBackgroundColorNavy: "#354e57",
  lightBlue: "#6caecb",
  productEndSectionBG: "#546d76",
};
