import VideoBox from "components/video-box/video-box";
import TestimonialTwo from "components/testimonial/testimonial-2";
import BlogsAreaTwo from "components/blogs/blog-area-2";
import BrandsAreaTwo from "components/brands/brand-area-2";
import FashionProducts from "components/products/fashion-products";
import FashionFeatureProducts from "components/products/fashion-feature-products";
import FooterThree from "layout/footers/footer-3";
import BlogArea from "components/blogs/blog-area";
import { Element } from "react-scroll";
import Wrapper from "layout/wrapper";
import HeaderFour from "layout/headers/header-4";
import HeroSliderSeven from "components/hero-banner/hero-banner-seven";
import ShopCategory from "components/shop/shop-category";
import { useEffect, useState } from "react";
import { jsonData as product_data } from "app/home-6/product-data";
import About from "components/about/about-section";
import TrendingProductsTwo from "components/products/trending-products-2";
import Footer from "layout/footers/footer";
import Support from "components/support/support-section";
import { useAppDispatch, useAppSelector } from "redux/hook";
import ErrorBoundary from "helpers/ErrorBoundaries";
import { fetchItems } from "redux/actions/items.action";
import "./LoadingSpinner.css";
import ShopCategoryNewDesign from "components/shop/shop-category-newdesign";
import BTECProductsSection from "components/products/recommended-products";
import TrendingProductsTwoBackup from "components/products/trending-products-2-backup";
import TrendingProducts from "components/products/trending-products";
import TrendingProductsWeekly from "components/products/trending-products-weekly";
import SupportV2 from "components/support/support-new-design";

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

const Spacing = ({ pixels }: { pixels?: number }) => (
  <div style={{ height: pixels ? `${pixels}px` : "100px" }}></div>
);

const MarginWrapper = ({
  children,
  pixels,
}: {
  children: JSX.Element;
  pixels?: number | undefined;
}) => {
  return (
    <div style={{ marginTop: pixels ? `${pixels}px` : "10px" }}>{children}</div>
  );
};

export default function HomePageSeven() {
  const dispatch = useAppDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const loadingSlide = useAppSelector((state) => state.slide.loading);
  const loadingCategories = useAppSelector((state) => state.category.loading);
  const loadingSettings = useAppSelector((state) => state.setting.loading);
  const loadingItems = useAppSelector((state) => state.items.loading);
  const loadingAddress = useAppSelector((state) => state.address.loading);

  useEffect(() => {
    dispatch(fetchItems({ page: 1, search: "", itemsPerPage: 10 }));
  }, []);

  const loadingPage =
    loadingSlide ||
    loadingCategories ||
    loadingSettings ||
    loadingItems ||
    loadingAddress;

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Wrapper>
      {loadingPage ? (
        <LoadingSpinner />
      ) : (
        <>
          <HeaderFour />
          <main>
            <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : 10}>
                <HeroSliderSeven />
                {/** // ? EDITED OLD DESIGN */}
              </MarginWrapper>
            </ErrorBoundary>

            <Spacing pixels={20} />

            <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : undefined}>
                <BTECProductsSection />
              </MarginWrapper>
            </ErrorBoundary>

            <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : undefined}>
                <ShopCategoryNewDesign />
              </MarginWrapper>
            </ErrorBoundary>

            {/* <ErrorBoundary> */}
            {/* <MarginWrapper pixels={isMobile ? 0 : undefined}> */}
            {/* <BTECProductsSection
                  title="STRAIGHT FROM BOLTTEC"
                  sub_title="NEW PRODUCTS"
                  hasDarkBackground={false}
                /> */}
            {/* <TrendingProductsTwoBackup /> */}
            {/* <About /> */}
            {/* </MarginWrapper> */}
            {/* </ErrorBoundary> */}

            <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : undefined}>
                <TrendingProductsTwo />
              </MarginWrapper>
            </ErrorBoundary>

            <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : undefined}>
                <VideoBox />
              </MarginWrapper>
            </ErrorBoundary>

            {/* // TODO: FEATURE: NEEDS AN API  */}
            {/* <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : undefined}>
                <TrendingProductsWeekly
                  title="TOP PRODUCTS"
                  sub_title="TRENDING THIS WEEK"
                />
              </MarginWrapper>
            </ErrorBoundary> */}

            <ErrorBoundary>
              <MarginWrapper pixels={isMobile ? 0 : 100}>
                <SupportV2 />
              </MarginWrapper>
            </ErrorBoundary>
          </main>
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </>
      )}
    </Wrapper>
  );
}
