import React from "react";
import { Link } from "react-router-dom";
import menuData from "data/menu-data";
import background from "assets/img/bg/mega-menu-bg.jpg";
import { Link as ReactScrollTo } from "react-scroll";

const NavManus = () => {
  return (
    <nav>
      <ul>
        {menuData?.map((item, index) => {
          return (
            <li
              key={index}
              className={`${
                item.hasDropdown && !item.megamenu
                  ? "active has-dropdown"
                  : item.megamenu && "mega-menu has-dropdown"
              }`}
            >
              <Link to={`${item.link}`}>{`${item.title}`}</Link>

              {item?.hasDropdown && !item.megamenu && (
                <ul className="submenu transition-3">
                  {item?.dropdownItems?.map((menu, index) => (
                    <li key={index}>
                      <Link to={`${menu.link}`}>{menu.title}</Link>
                    </li>
                  ))}
                </ul>
              )}

              {item.hasDropdown && item.megamenu && (
                <ul
                  className="submenu transition-3"
                  style={{
                    background: background,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center right",
                    backgroundSize: "cover",
                  }}
                >
                  {item.dropdownItems &&
                    item.dropdownItems.map((menu, index) => (
                      <li
                        key={index}
                        className={`${menu.dropdownMenu ? "has-dropdown" : ""}`}
                      >
                        <Link to={`${menu.link}`}>{menu.title}</Link>
                        <ul>
                          {menu?.dropdownMenu?.map((m, index) => (
                            <li key={index}>
                              <Link to={`${m.link}`}>{m.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavManus;
