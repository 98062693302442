import { createSlice } from "@reduxjs/toolkit";
import { fetchSlideShow } from "redux/actions/slide.action";

interface slideShowData {
  _id: string;
  img: string;
  header_text: string;
  title: string;
  description: string;
  link: string;
  isActive: boolean;
  isDeleted: boolean;
  __v: number;
}

type InitialSlideShowStateTypes = {
  loading: boolean;
  slideShowData: slideShowData[];
  error: null | string;
};

const initialState: InitialSlideShowStateTypes = {
  loading: false,
  slideShowData: [],
  error: null,
};

const slideShowSlice = createSlice({
  name: "slide",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSlideShow.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSlideShow.fulfilled, (state, action) => {
      state.loading = false;
      state.slideShowData = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSlideShow.rejected, (state, action) => {
      state.loading = false;
      state.slideShowData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default slideShowSlice.reducer;
