import { createSlice } from "@reduxjs/toolkit";
import { fetchCategory } from "redux/actions/category.action";

interface categoryData {
  _id: string;
  title: string;
  categoryImage: string;
}

type InitialCategoryStateTypes = {
  loading: boolean;
  categoryData: categoryData[];
  error: null | string;
};

const initialState: InitialCategoryStateTypes = {
  loading: false,
  categoryData: [],
  error: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categoryData = action.payload;
      state.error = null;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      state.loading = false;
      state.categoryData = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default categorySlice.reducer;
