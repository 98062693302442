import { useRoutes, Navigate, RouteObject } from "react-router-dom";
import HomePageSeven from "app/home7/page";
import { HomePageSix } from "app/home-6/page";
import P404 from "components/404/P404";
import ProductDetailsPage from "app/product-details/page";
import ProductDetailsPageID from "app/product-details/id/page";
import { Suspense } from "react";
import HomePageFive from "app/home-5/page";
import HomePageFour from "app/home-4/page";
import ShopPage from "app/shop/page";
import HomePageThree from "app/home-3/page";
import HomePageTwo from "app/home-2/page";
import ContactPage from "app/contact/page";
import ShopThreeColPage from "app/shop-3-col/page";
import Legal from "app/legal/page";
import ShopThreeColPageCateg from "app/shop-3-col/page-product-category";
import About from "app/about/about";
import ErrorBoundary from "helpers/ErrorBoundaries";
import ContactPageV2 from "app/contact-new/page";
import ShopThreeColPageV2 from "app/shop-3-col/pagev2";

export const RoutesAsObj = () => {
  const Routes: RouteObject[] = [
    {
      path: "/",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <HomePageSeven />
        </Suspense>
      ),
    },
    {
      path: "/about",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            <About />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/legal",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            <Legal />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/products",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            <ShopThreeColPage />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/new-arrivals",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            <ShopThreeColPageV2 />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/product-category",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            <ShopThreeColPageCateg />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/contact",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            {/* <ContactPage /> */}
            <ContactPageV2 />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/shop",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ErrorBoundary>
            <ShopPage />
          </ErrorBoundary>
        </Suspense>
      ),
    },
    {
      path: "/details-page",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ProductDetailsPage />
        </Suspense>
      ),
    },
    {
      path: "/details-page/:id",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ProductDetailsPageID />
        </Suspense>
      ),
    },
    {
      path: "/product-details/:id",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <ProductDetailsPageID />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <P404 text="Page not found!" />
        </Suspense>
      ),
    },
    // un-used
    {
      path: "/home-2",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <HomePageTwo />
        </Suspense>
      ),
    },
    {
      path: "/home-3",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <HomePageThree />
        </Suspense>
      ),
    },
    {
      path: "/home-4",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <HomePageFour />
        </Suspense>
      ),
    },
    {
      path: "/home-5",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <HomePageFive />
        </Suspense>
      ),
    },
    {
      path: "/home-6",
      element: (
        <Suspense fallback={<h3>Loading...</h3>}>
          <HomePageSix />
        </Suspense>
      ),
    },
  ];

  let element = useRoutes(Routes);

  return <>{element}</>;
};
