import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOME_PATH, printData } from "helpers/helpers";
import { setNumOfPages } from "redux/reducers/items.reducer";

type NumStr = string | number;

type QueryItem = {
  page: NumStr;
  search?: NumStr;
  itemsPerPage: NumStr;
};

type QueryItemByCateg = QueryItem & {
  category: NumStr;
};

export const fetchItems = createAsyncThunk(
  "items/fetchItems",
  async (
    { page = 1, search = "", itemsPerPage = 100 }: QueryItem,
    { dispatch }
  ) => {
    const url =
      search === ""
        ? `${HOME_PATH.items}?itemsPerPage=${itemsPerPage}&page=${page}`
        : `${HOME_PATH.items}?page=${page}&search=${search}&itemsPerPage=${itemsPerPage}`;
    try {
      const response = await axios.get(url);
      if (response) {
        dispatch(setNumOfPages(response.data.totalPages));
      }
      return response.data.items;
    } catch (error) {
      printData({ name: "fetchItems", data: error, type: "error" });
    }
  }
);

export const fetchSingleItemByID = createAsyncThunk(
  "items/fetchSingleItemByID",
  async ({ _id }: { _id: NumStr }, {}) => {
    try {
      const response = await axios.get(`${HOME_PATH.items}/${_id}`);
      return response.data;
    } catch (error) {
      printData({ name: "fetchSingleItemByID", data: error, type: "error" });
    }
  }
);

export const fetchItemsByCategory = createAsyncThunk(
  "items/fetchItemsByCategory",
  async (
    { page = 1, category = "", itemsPerPage = 100 }: QueryItemByCateg,
    {}
  ) => {
    const url = `${HOME_PATH.items_by_category}?page=${page}&category=${category}&itemsPerPage=${itemsPerPage}`;
    try {
      const response = await axios.get(url);

      return response.data.items;
    } catch (error) {
      printData({ name: "fetchItemsByCategory", data: error, type: "error" });
    }
  }
);
