import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOME_PATH, printData } from "helpers/helpers";

export const fetchAddress = createAsyncThunk(
  "address/fetchAddress",
  async () => {
    try {
      const response = await axios.get(HOME_PATH.address);
      return response.data;
    } catch (error) {
      printData({ name: "address", data: error, type: "error" });
    }
  }
);
