import { createSlice } from "@reduxjs/toolkit";
import {
  fetchItems,
  fetchItemsByCategory,
  fetchSingleItemByID,
} from "redux/actions/items.action";

type Category = {
  _id: string;
  title: string;
  categoryImage: string;
};

export type Item = {
  _id: string;
  skuNumber: number | string;
  title: string;
  description: string;
  mainImage: string;
  hoverImage: string;
  category: Category;
  __v: number;
  featured: boolean;
  new: boolean;
  isHighlighted: boolean | string;
  mediaUserManual: string;
};

export type ItemArray = Item[];

export type MediaItem = {
  _id: string;
  tableName: string;
  recordId: string;
  image: string;
  __v: number;
};

type ItemColors = {
  _id: string;
  item: string;
  colorText: string;
  colorImage: string;
  __v: number;
};

export type OneItem = {
  items: {
    _id: string;
    skuNumber: number;
    title: string;
    description: string;
    mainImage: string;
    hoverImage: string;
    category: {
      _id: string;
      title: string;
      categoryImage: string;
    };
    __v: number;
  };
  itemColors: ItemColors[] | any;
  media?: MediaItem[];
};

type InitialItemsStateTypes = {
  itemData: ItemArray;
  loading: boolean;
  error: null | string;
  oneItem: OneItem;
  oneItemLoading: boolean;
  oneItemErr: null | string;
  itemsByCateg: ItemArray;
  itemsByCategLoading: boolean;
  itemsByCategError: null | string;
  numOfPages: number;
};

const initialOneItemState = {
  items: {
    _id: "",
    skuNumber: 0,
    title: "",
    description: "",
    mainImage: "",
    hoverImage: "",
    category: {
      _id: "",
      title: "",
      categoryImage: "",
    },
    __v: 0,
  },
  itemColors: undefined,
};

const initialState: InitialItemsStateTypes = {
  // all / search
  itemData: [],
  loading: false,
  error: null,
  numOfPages: 0,
  // one item
  oneItem: initialOneItemState,
  oneItemLoading: false,
  oneItemErr: null,
  // item by category
  itemsByCateg: [],
  itemsByCategLoading: false,
  itemsByCategError: null,
};

const itemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    // <> fetchItems
    builder.addCase(fetchItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchItems.fulfilled, (state, action) => {
      state.loading = false;
      state.itemData = action.payload;
      state.error = null;
    });
    builder.addCase(fetchItems.rejected, (state, action) => {
      state.loading = false;
      state.itemData = [];
      state.error =
        action.error.message || "Something went wrong fetching products";
    });

    // <> fetchSingleItemByID
    builder.addCase(fetchSingleItemByID.pending, (state) => {
      state.oneItemLoading = true;
    });
    builder.addCase(fetchSingleItemByID.fulfilled, (state, action) => {
      state.oneItemLoading = false;
      state.oneItem = action.payload;
      state.oneItemErr = null;
    });
    builder.addCase(fetchSingleItemByID.rejected, (state, action) => {
      state.oneItemLoading = false;
      state.oneItem = initialOneItemState;
      state.oneItemErr =
        action.error.message || "Something went wrong fetching single product";
    });

    // <> fetch Items By Category
    builder.addCase(fetchItemsByCategory.pending, (state) => {
      state.itemsByCategLoading = true;
    });
    builder.addCase(fetchItemsByCategory.fulfilled, (state, action) => {
      state.itemsByCategLoading = false;
      state.itemsByCateg = action.payload;
      state.itemsByCategError = null;
    });
    builder.addCase(fetchItemsByCategory.rejected, (state, action) => {
      state.itemsByCategLoading = false;
      state.itemsByCateg = [];
      state.itemsByCategError =
        action.error.message ||
        "Something went wrong fetching prods by category";
    });
  },
});

export default itemSlice.reducer;
export const { setNumOfPages } = itemSlice.actions;
