import { IHeroSlider } from "../types/hero-slider-t";

//SLIDESHOW_DATA

// hero slider one start
const hero_slider_one: IHeroSlider[] = [
  {
    id: 1,
    bgImg: require("assets/img/slider/slider-1.jpg"),
    title: "Handmade <br /> Hand carved Coffee",
    subtitle:
      "As rich and unique as the coffee beans it is intended for, this little scoop will make your morning ritual a special occasion every day.",
  },
  {
    id: 2,
    bgImg: require("assets/img/slider/slider-2.jpg"),
    title: "Think Different &<br /> Do it otherwise",
    subtitle:
      "Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.",
  },
  {
    id: 3,
    bgImg: require("assets/img/slider/slider-3.jpg"),
    title: "High Beam<br /> by Tom Chung",
    subtitle:
      "High Beam is an adjustable desk or shelf light that offers a wide variety of lighting possibilities",
  },
];

// hero slider two
const hero_slider_two: IHeroSlider[] = [
  {
    id: 1,
    bgImg: require("assets/img/slider/03/slider-01.jpg"),
    isDark: true,
    title: "Lighting <br /> Creative Furniture",
    subtitle:
      "From luxury watches and chronographs to wall clocks and weather stations, Henning Koppels.",
  },
  {
    id: 2,
    bgImg: require("assets/img/slider/03/slider-02.jpg"),
    title: "Clock <br /> Creative Furniture",
    subtitle:
      "The Drop™ chair was designed by Arne Jacobsen in 1958 as part of his masterpiece, the legendary Radisson Blu Royal Hotel in Copenhagen.",
  },
  {
    id: 3,
    bgImg: require("assets/img/slider/03/slider-03.jpg"),
    title: "Drop Chair <br /> The Black Leather Edition",
    subtitle:
      "From luxury watches and chronographs to wall clocks and weather stations, Henning Koppels.",
  },
];

// hero slider four
const hero_slider_four: IHeroSlider[] = [
  {
    id: 1,
    bgImg: require("assets/img/slider/04/slider-01.jpg"),
    title: "Handmade <br /> Hand carved Coffee",
    subtitle:
      "As rich and unique as the coffee beans it is intended for, this little scoop will make your morning ritual a special occasion every day.",
  },
  {
    id: 2,
    bgImg: require("assets/img/slider/slider-2.jpg"),
    title: "Think Different &<br /> Do it otherwise",
    subtitle:
      "As rich and unique as the coffee beans it is intended for, this little scoop will make your morning ritual a special occasion every day.",
  },
  {
    id: 3,
    bgImg: require("assets/img/slider/slider-3.jpg"),
    title: "High Beam <br /> by Tom Chung",
    subtitle:
      "As rich and unique as the coffee beans it is intended for, this little scoop will make your morning ritual a special occasion every day.",
  },
];

// hero slider three
const hero_slider_seven: IHeroSlider[] = [
  {
    // hair drier
    id: 1,
    bgImg: require("assets/img/slider/05/Artboard1.webp"),
    sm_title: "negative ions tech",
    meta: true,
    title: "BOLT PROFESSIONAL HAIR DRYER",
    subtitle:
      "BOLT PROFESSIONAL HAIR DRYER (BLDC) The ultra-light and compact dryer weighs only 180 g, providing you with convenience and ease of use.",
  },
  {
    // ice
    id: 2,
    // bgImg: require("assets/img/bolt-tec/ice-maker/Icemaker1.jpg"),
    bgImg: require("assets/img/slider/05/Artboard2.webp"),
    sm_title: "144 lbs daily capacity.",
    title: "Fast & Efficient Ice Making",
    subtitle: "7 minutes fast ice making & 12 pcs bullet ices per cycle",
  },
  {
    //steam
    id: 3,
    // bgImg: require("assets/img/bolt-tec/hair-dryer/BoltHairDryer1.jpg"),
    bgImg: require("assets/img/slider/05/Artboard3.webp"),
    sm_title: "Quick parcel delivery",
    title: "Rako Vapour Handy Portable Garment Steamer.",
    subtitle:
      "Fast heat-up technology & 250ml large water tank. This incredible powerful handheld clothes steamer produces steam in less than 25 seconds, less waiting and save time.",
  },
];

export const HeroSliderData = {
  hero_slider_one,
  hero_slider_two,
  hero_slider_four,
  hero_slider_seven,
};
