import { ICategoryType } from "../types/category-d-t";
import bannerSM1 from "assets/img/shop/banner/banner-big-1.jpg";
import bannerSM2 from "assets/img/shop/banner/banner-big-2.jpg";
import bannerSm2f from "assets/img/shop/banner/banner-sm-3.jpg";
// used below
import bannerSM3 from "assets/img/shop/banner/02/banner-1.webp";
import banner4 from "assets/img/shop/banner/02/banner-2.webp";
import banner5 from "assets/img/shop/banner/02/banner-3.webp";
// real below for part below slideshow
import prod1 from "assets/img/shop/banner/02/thinmb1.webp";
import prod2 from "assets/img/shop/banner/02/thumb2.webp";
import prod3 from "assets/img/shop/banner/02/thumb3.webp";
// real below for part below about us
import clothing from "assets/img/shop/banner/ClothingCareDevices.webp";
import hair from "assets/img/shop/banner/HairCareProducts.webp";
import ice from "assets/img/shop/banner/IceMakingMachines.webp";

const category_data: ICategoryType[] = [
  // for section right below about us
  {
    id: 1,
    img: clothing,
    parentTitle: "Lighting & Chair",
    children: ["Lighting", "Chair"],
  },
  {
    id: 2,
    img: hair,
    parentTitle: "Decoration & Accessories",
    children: ["Decoration", "Accessories"],
  },
  {
    id: 3,
    img: ice,
    parentTitle: "Clothing & Oil",
    children: ["Clothing", "Oil"],
  },
  // for section right below the slideshow
  {
    id: 4,
    img: prod1,
    parentTitle: "BOLT PROFESSIONAL HAIR DRYER",
    children: ["Shirt"],
    smDesc:
      "BOLT PROFESSIONAL HAIR DRYER (BLDC) The ultra-light and compact dryer weighs only 180 g.",
  },
  {
    id: 5,
    img: prod2,
    parentTitle: "FAST & EFFICIENT ICE MAKING",
    children: ["Shoes"],
    smDesc: "7 minutes fast ice making & 12 pcs bullet ices per cycle.",
  },
  {
    id: 6,
    img: prod3,
    parentTitle: "RAKO VAPOUR HANDY PORTABLE GARMENT STEAMER.",
    children: ["Shoes"],
    smDesc:
      "Fast heat-up technology & 250ml large water tank. This incredible powerful handheld clothes steamer produces steam in less than 25 seconds.",
  },
];

export default category_data;
