import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./features/cart";
import wishlistSlice from "./features/wishlist";
import compareSlice from "./features/compare";
import utility from "./features/utility";
import filter from "./features/filter";
// real reducer start
import slide from "./reducers/slide.reducer";
import category from "./reducers/category.reducer";
import settings from "./reducers/setting.reducer";
import items from "./reducers/items.reducer";
import contact from "./reducers/contact.reducer";
import address from "./reducers/address.reducer";

export const store = configureStore({
  reducer: {
    cart: cartSlice,
    wishlist: wishlistSlice,
    compare: compareSlice,
    utility: utility,
    filter: filter,
    // real reducers start
    slide: slide,
    category: category,
    setting: settings,
    items: items,
    contact: contact,
    address: address,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
