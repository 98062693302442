import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOME_PATH, printData } from "helpers/helpers";

export const fetchCategory = createAsyncThunk(
  "category/fetchCategory",
  async () => {
    try {
      const response = await axios.get(HOME_PATH.category);
      return response.data;
    } catch (error) {
      printData({ name: "category", data: error, type: "error" });
    }
  }
);
