import axios, { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { HOME_PATH, printData } from "helpers/helpers";

type Contact = {
  email: string;
  subject: string;
  message: string;
  name: string;
};

export const sendContactMessage = createAsyncThunk(
  "contact/sendMessage",
  async ({ email, subject, message, name }: Contact, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(HOME_PATH.contact, {
        email,
        subject,
        message,
        name,
      });

      return data;
    } catch (error) {
      if (error instanceof AxiosError)
        if (error.response && error.response.data.message) {
          printData({
            name: "contact/sendMessage",
            data: error,
            type: "error",
          });
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
    }
  }
);
