import HeaderFour from "layout/headers/header-4"
import Wrapper from "layout/wrapper"
import { useEffect, useState } from "react"
import Breadcrumb from "components/common/breadcrumb"
import breadcrumb_bg from "assets/img/page-title/page-title-2.jpg"
import Footer from "layout/footers/footer"
import { useAppSelector } from "redux/hook"
import { Img } from "react-image"
import { SERVER_URL } from "helpers/helpers"
import "./about.css"
import PIC1 from "assets/img/about/Bolt_01-1.png"
import PIC2 from "assets/img/about/Bolt_01-2.png"
import SupportV2 from "components/support/support-new-design"
import img from "assets/img/about/world-map-vector.png"

type Props = {}

export default function About({}: Props) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const settings = useAppSelector((state) => state.setting.settingsInfo)
  const loadingSettings = useAppSelector((state) => state.setting.loading)
  const aboutBanner = settings[0]?.aboutBanner

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768) // Adjust the screen width as needed for mobile
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const mobileBackgroundStyles = isMobile
    ? {
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }
    : {}

  return (
    <Wrapper>
      <HeaderFour />

      <main>
        {/* <Breadcrumb
          mobileBackgroundStyles={mobileBackgroundStyles}
          bannerImg={aboutBanner}
          bg_img={breadcrumb_bg}
          title=""
          subtitle=""
        /> */}

        <>
          {/* <h1
            style={{
              textAlign: "center",
              marginTop: "100px",
              fontWeight: "bolder",
            }}
          >
            ABOUT US
          </h1> */}
          <section className="contact__area pb-100 pt-95 mt-50">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                marginBottom: "10px",
              }}
              className="image"
            >
              <Img
                loading="lazy"
                height={300}
                width={"100%"}
                src={SERVER_URL + settings[0]?.aboutImg}
                loader={<div className="spinner"></div>}
              />
            </div>

            <div className="about-images-wrapper">
              <img className="about-image" src={PIC1} />
              <img className="about-image" src={PIC2} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
              <div
                className="about-us-parent_"
                style={{
                  backgroundImage: `url(${img})`,
                  backgroundSize: "cover", // adjust as needed
                  backgroundPosition: "center", // adjust as needed
                }}
                // className="container"
              >
                <div className="about-us-container">
                  {loadingSettings ? null : (
                    <div className="about-us-content">
                      <div className="description">
                        <h2
                          style={{
                            textAlign: "center",
                            marginBottom: "25px",
                            fontWeight: "bolder",
                            fontSize: "55px",
                          }}
                        >
                          BOLT PRODUCTS
                        </h2>
                        <p
                          className="about-text_"
                          style={{
                            color: "#2e2e22",
                            textAlign: "center",
                            lineHeight: "40px",
                            padding: "15px",
                          }}
                        >
                          {settings[0]?.aboutText}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <SupportV2 />
        </>
      </main>

      <Footer />
    </Wrapper>
  )
}
