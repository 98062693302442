import { createSlice } from "@reduxjs/toolkit";
import { fetchSettings } from "redux/actions/settings.action";

interface SettingsInfo {
  _id: string;
  facebook: string;
  instagram: string;
  linkedin: string;
  aboutText: string;
  aboutImg: string;
  brandInfo: string;
  brandImg: string;
  whatsappNb: string;
  __v: number;
  privacyPolicy: string;
  termsConditions: string;
  // bannerImg: string;
  aboutBanner: string;
  contactBanner: string;
  legalBanner: string;
  productsBanner: string;
  categoryBanner: string;
}

type InitialSettingsStateTypes = {
  loading: boolean;
  settingsInfo: SettingsInfo[];
  error: null | string;
};

const initialState: InitialSettingsStateTypes = {
  loading: false,
  settingsInfo: [],
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      state.loading = false;
      state.settingsInfo = action.payload;
      state.error = null;
    });
    builder.addCase(fetchSettings.rejected, (state, action) => {
      state.loading = false;
      state.settingsInfo = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default settingsSlice.reducer;
