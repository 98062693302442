import Slider, { Settings } from "react-slick";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hook";
import { SERVER_URL } from "helpers/helpers";
import "swiper/css";
import { CSSProperties, useEffect, useRef, useState } from "react";

const ShopCategoryNewDesign = () => {
  const categoryData =
    useAppSelector((state) => state.category.categoryData) ?? [];
  const [hoveringCategory, setHoveringCategory] = useState<number | null>(null);
  const sliderRef = useRef<Slider | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: CSSProperties = !isMobile
    ? {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "7px",
        marginRight: "7px",
      }
    : {};

  const settings: Settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3500,
    dots: false,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: isMobile ? 1 : 1,
  };

  return (
    <section className="categories_carousel slider__area slider__area-3 p-relative mt-20">
      <button
        onClick={() => sliderRef.current?.slickPrev()}
        className="slick-prev slick-arrow"
      >
        <i className="fal fa-angle-left"></i>
      </button>
      <Slider ref={sliderRef} className="slider-active-3" {...settings}>
        {categoryData.map((banner, index) => (
          <div key={banner._id}>
            <div style={styles}>
              <div
                onMouseEnter={() => {
                  setHoveringCategory(index);
                }}
                onMouseLeave={() => {
                  setHoveringCategory(null);
                }}
                className="category_item single-slider single-slider-2 slider__height-6 d-flex align-items-center"
                style={{
                  backgroundImage: `url(${SERVER_URL + banner?.categoryImage})`,
                  objectFit: "contain",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  position: "relative", // Add position relative to parent
                }}
              >
                <div
                  style={{
                    position: "absolute", // Position the title absolutely
                    bottom: 0, // Place it at the bottom
                    left: 0, // Center it horizontally
                    width: "100%", // Make it full width
                    backdropFilter:
                      hoveringCategory === index || isMobile
                        ? "blur(5px)"
                        : undefined,
                    backgroundColor:
                      hoveringCategory === index || isMobile
                        ? "rgba(0, 0, 0, 0.4)"
                        : undefined,
                    padding: "25px", // Add some padding for better visibility
                  }}
                >
                  <Link
                    state={{ searchQuery: banner._id, from: "categories" }}
                    to="/product-category"
                  >
                    <h5
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "18px",
                        margin: 0, // Remove default margin for better alignment
                      }}
                    >
                      {banner?.title}
                    </h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <button
        onClick={() => sliderRef.current?.slickNext()}
        className="slick-next slick-arrow"
      >
        <i style={{ fontSize: "25px" }} className="fal fa-angle-right"></i>
      </button>
    </section>
  );
};

export default ShopCategoryNewDesign;
