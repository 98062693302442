import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { HOME_PATH, printData } from "helpers/helpers";

export const fetchSettings = createAsyncThunk(
  "settings/fetchSettings",
  async () => {
    try {
      const response = await axios.get(HOME_PATH.setting);
      return response.data;
    } catch (error) {
      printData({ name: "settings", data: error, type: "error" });
    }
  }
);
