import React from "react";

const ErrorMsg = ({
  msg,
  isLast = false,
}: {
  msg: string;
  isLast?: boolean;
}) => {
  return (
    <div
      style={{
        color: "red",
        marginTop: isLast ? -50 : undefined,
        marginBottom: isLast ? 30 : undefined,
      }}
    >
      {msg}
    </div>
  );
};

export default ErrorMsg;
