import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";
import { RoutesAsObj } from "routes/RoutesAsObj";
import ScrollToTop from "helpers/scrollToTop";
import { ToastContainer } from "react-toastify";
import { fetchAddress } from "redux/actions/address.action";
import { useAppDispatch } from "redux/hook";
import { fetchSlideShow } from "redux/actions/slide.action";
import { fetchCategory } from "redux/actions/category.action";
import { fetchSettings } from "redux/actions/settings.action";
import { fetchItems } from "redux/actions/items.action";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSlideShow());
    dispatch(fetchCategory());
    dispatch(fetchSettings());
    dispatch(fetchItems({ page: 1, search: "", itemsPerPage: 10 }));
    dispatch(fetchAddress());
  }, []);

  return (
    <>
      <ScrollToTop />
      <RoutesAsObj />
      <ToastContainer limit={2} />
    </>
  );
}

export default App;
