// import { Link } from "react-router-dom";
// import { IProduct } from "types/product-d-t";
// import ProductItem from "./single-product/product-item";
import { Item, ItemArray, OneItem } from "redux/reducers/items.reducer";
// import ProductItemTwo from "./single-product/product-item-2";
import ProductItemTwoV2 from "./single-product/product-item-2-v2";
// import { useAppSelector } from "redux/hook";
import { Key } from "react";

const RelatedProductsV2 = ({ uniqueItems }: any) => {
  return (
    <section className="related__product pb-60">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="section__title-wrapper text-center mb-55">
              <div className="section__title mb-10"></div>
            </div>
          </div>
        </div>
        <h2 className="mb-50" style={{ textAlign: "center" }}>
          Related Products
        </h2>
        <div className="row">
          {uniqueItems?.map((item: Item, i: Key | null | undefined) => (
            <div key={i} className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="product__item">
                <ProductItemTwoV2 item={item} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RelatedProductsV2;
// import { Link } from "react-router-dom";
// import { IProduct } from "types/product-d-t";
// import ProductItem from "./single-product/product-item";

// type IProps = {
//   product_data: IProduct[];
//   product: IProduct;
// };
// const RelatedProducts = ({ product_data, product }: IProps) => {
//   const related_product = product_data.filter(
//     (p) => p.category === product.category
//   );
//   return (
//     <section className="related__product pb-60">
//       <div className="container">
//         <div className="row">
//           <div className="col-xl-12">
//             <div className="section__title-wrapper text-center mb-55">
//               <div className="section__title mb-10">
//                 <h2>Trending Products</h2>
//               </div>
//               <div className="section__sub-title">
//                 <p>
//                   Mirum est notare quam littera gothica quam nunc putamus parum
//                   claram!
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row">
//           {related_product.map((product, i) => (
//             <div key={i} className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
//               <div className="product__item">
//                 <ProductItem product={product} />
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default RelatedProducts;
