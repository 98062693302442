import { createSlice } from "@reduxjs/toolkit";
import { fetchAddress } from "redux/actions/address.action";

type ContactInfo = {
  _id: string;
  addressInfo: string;
  email: string;
  phoneNumber: number;
  mapLink: string;
  isActive: boolean;
  isDefault: boolean;
  isDeleted: boolean;
  __v: number;
};

type InitialAddressStateTypes = {
  loading: boolean;
  contactInfo: ContactInfo[];
  error: null | string;
};

const initialState: InitialAddressStateTypes = {
  loading: false,
  contactInfo: [],
  error: null,
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAddress.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAddress.fulfilled, (state, action) => {
      state.loading = false;
      state.contactInfo = action.payload;
      state.error = null;
    });
    builder.addCase(fetchAddress.rejected, (state, action) => {
      state.loading = false;
      state.contactInfo = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default categorySlice.reducer;
