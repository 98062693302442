import { Link } from "react-router-dom";
import { IProduct } from "types/product-d-t";
// import Image from 'next/image';
import ProductItem from "./single-product/product-item";
import { useAppSelector } from "redux/hook";
import "./trendingProd2.css";
import { SERVER_URL } from "helpers/helpers";
import { CSSProperties } from "react";
import { BOLTColors } from "config/colors";
import ProductItemCol from "./product-item-col";

const bottomButtonStyle: CSSProperties = {
  cursor: "pointer",
  border: "1px solid " + BOLTColors.textBorder,
  textAlign: "center",
  width: "220px",
  padding: "13px",
  borderRadius: "5px",
  color: BOLTColors.darkText,
  fontWeight: 500,
  fontSize: "14px",
  textTransform: "capitalize",
};

const titleStyle: CSSProperties = {
  textTransform: "uppercase",
  fontWeight: "lighter",
  fontSize: "20px",
};

const subTitleStyle: CSSProperties = {
  fontWeight: "bold",
  textTransform: "uppercase",
};

const BTECProductsSection = ({
  hasDarkBackground = true,
  title = "EXPLORE AWESOME PRODUCTS",
  sub_title = "RECOMMENDED FOR YOU",
}) => {
  const items = useAppSelector((state) => state.items.itemData) ?? [];
  // const slicedArray = items?.slice(1)
  const featuredItems = items?.filter((item) => item?.featured == true);
  const highlightedItem = featuredItems?.find((item) => {
    return item.isHighlighted == true || item.isHighlighted == "true";
  });

  return (
    <section
      style={{
        backgroundColor: hasDarkBackground
          ? BOLTColors.sectionSilverBg
          : undefined,
      }}
      className="product__area"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="section__title-wrapper text-center mb-25">
              <div className="mb-10 mt-10">
                <h5 style={titleStyle}>{title}</h5>
                <h2 style={subTitleStyle}>{sub_title}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="product__banner p-relative">
          <div className="row">
            {featuredItems?.slice(0, 4).map((item, idx) => (
              <ProductItemCol
                _id={item?._id}
                hoverImage={item?.hoverImage}
                mainImage={item?.mainImage}
                idx={idx}
                skuNumber={item?.skuNumber}
                title={item?.title}
                key={idx}
              />
            ))}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/products"}>
            <p style={bottomButtonStyle}>Explore other products</p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default BTECProductsSection;
