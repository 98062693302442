import { promises as fs } from "fs";
import React from "react";
// import { Metadata } from "next";
import Wrapper from "layout/wrapper";
import Header from "layout/headers/header";
import Breadcrumb from "components/common/breadcrumb";
import Footer from "layout/footers/footer";
// import ShopArea from "components/shop/shop-area";
import ShopAreaCategory from "components/shop/shop-area-category";
import { jsonData } from "../home-6/product-data";
import HeaderFour from "layout/headers/header-4";
import { useAppSelector } from "redux/hook";

// export const metadata: Metadata = {
//   title: "Shop 3 Col Page",
// };

export default function ShopThreeColPageCateg() {
  // const file = await fs.readFile(
  //   process.cwd() + "/src/app/product-data.json",
  //   "utf8"
  // );

  // const product_data = JSON.parse(file);
  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const categoryBanner = settings[0]?.categoryBanner;

  return (
    <Wrapper>
      {/* header start */}
      <HeaderFour />
      {/* header end */}

      <main>
        {/* breadcrumb start */}
        <Breadcrumb
          bannerImg={categoryBanner}
          title="Product Categories"
          mid_title="Products"
          subtitle="Product Categories"
        />
        {/* breadcrumb end */}

        {/* shop area start */}
        <ShopAreaCategory
          shop_col="col-xl-4 col-lg-4 col-md-6 col-sm-6 custom-col-10"
          product_data={jsonData}
        />
        {/* shop area end */}
      </main>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Wrapper>
  );
}
