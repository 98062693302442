import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
// import { HeroSliderData } from "data/hero-slider-data";
// import bolt1 from "assets/img/bolt-tec/Bolt_01-41.png";
// import bolt2 from "assets/img/bolt-tec/Bolt_01-42.png";
import { useAppSelector } from "redux/hook";
import { SERVER_URL } from "helpers/helpers";
import { BOLTColors } from "config/colors";

// slick setting
const settings = {
  arrows: false,
  autoplay: false,
  autoplaySpeed: 10000,
  dots: true,
  fade: true,
};

const HeroSliderSeven = () => {
  const sliderRef = useRef<Slider | null>(null);
  const slideshowData =
    useAppSelector((state) => state.slide.slideShowData) ?? [];
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768); // Adjust the screen width as needed for mobile
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mobileBackgroundStyles = isMobile
    ? {
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }
    : {};

  return (
    <section className="s_show_parent slider__area slider__area-3 p-relative pt-100">
      {/* <button
        onClick={() => sliderRef.current?.slickPrev()}
        className="slick-prev slick-arrow"
      >
        <i className="fal fa-angle-left"></i>
      </button> */}
      <Slider ref={sliderRef} className="slider-active-3" {...settings}>
        {slideshowData?.map((slider, index) => {
          return (
            <div key={index}>
              <div
                className="single-slider single-slider-2 slider__height-6 d-flex align-items-center"
                style={{
                  backgroundImage: `url(${SERVER_URL + slider?.img})`,
                  ...mobileBackgroundStyles,
                }}
              >
                <div
                  className="container custom-container-2"
                  style={{
                    backdropFilter: isMobile ? "blur(5px)" : undefined,
                    backgroundColor: isMobile
                      ? "rgba(255, 255, 255, 0.6)"
                      : undefined,
                    padding: isMobile ? "10px" : undefined,
                    marginLeft: isMobile ? undefined : "100px",
                  }}
                >
                  <div className="col-xl-7 col-lg-7 col-md-10">
                    <div className="slider__content slider__content-5">
                      {/* <span className={`${index === 0 ? "meta" : ""}`}>
                          {slider?.title}
                        </span> */}
                      <h2
                        style={{
                          color: BOLTColors.black,
                          textTransform: "uppercase",
                        }}
                        dangerouslySetInnerHTML={{ __html: slider?.title }}
                      ></h2>
                      <p style={{ color: "black" }}>{slider?.description} </p>
                      {/* removed anim to make it clickable in the first 2 slides  */}
                      {/* <div className="hero-slider-btn"> */}
                      {/* <Link to="/products" className="os-btn">
                          Discover now
                        </Link> */}
                      <Link className="read_more_button" to="/products">
                        {/** // TODO: use prod id */}
                        Read More
                      </Link>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>

      {/* <button
        onClick={() => sliderRef.current?.slickNext()}
        className="slick-next slick-arrow"
      >
        <i className="fal fa-angle-right"></i>
      </button> */}
    </section>
  );
};

export default HeroSliderSeven;
