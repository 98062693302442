import SocialLinks from "layout/footers/social-links";
import ContactForm from "../forms/contact-form";
import {
  handleCallClick,
  handleEmailClick,
  handleLocationClick,
} from "helpers/helpers";
import { useAppSelector } from "redux/hook";

const ContactArea = () => {
  const { contact, loading } = useAppSelector((state) => ({
    contact: state.address.contactInfo,
    loading: state.address.loading,
  }));

  const contactInfo = [
    {
      icon: "fal fa-map-marker-alt",
      title: "Address:",
      subtitle: loading ? "Loading..." : contact[0]?.addressInfo,
      action: () =>
        handleLocationClick(
          "https://www.google.com/maps/dir//25.274504,51.498413"
        ),
    },
    {
      icon: "fal fa-envelope-open-text",
      title: "Email:",
      subtitle: loading ? "Loading..." : contact[0]?.email,
      action: () => handleEmailClick("Support@bolt-tec.com"),
    },
    {
      icon: "fal fa-phone-alt",
      title: "Number Phone:",
      subtitle: loading ? "Loading..." : contact[0]?.phoneNumber,
      action: () => handleCallClick("+974 1234567"),
    },
  ];

  return (
    <>
      <section className="contact__area pb-100 pt-95">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="contact__form">
                <h1
                  className="mb-50"
                  style={{ textAlign: "center", fontSize: "50px" }}
                >
                  Let's talk about your Project
                </h1>
                {/* contact form start */}
                <ContactForm />
                {/* contact form end */}
                <p className="ajax-response"></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
// import SocialLinks from "layout/footers/social-links";
// import ContactForm from "../forms/contact-form";
// import {
//   handleCallClick,
//   handleEmailClick,
//   handleLocationClick,
// } from "helpers/helpers";
// import { useAppSelector } from "redux/hook";

// const ContactArea = () => {
//   const { contact, loading } = useAppSelector((state) => ({
//     contact: state.address.contactInfo,
//     loading: state.address.loading,
//   }));

//   const contactInfo = [
//     {
//       icon: "fal fa-map-marker-alt",
//       title: "Address:",
//       subtitle: loading ? "Loading..." : contact[0]?.addressInfo,
//       action: () =>
//         handleLocationClick(
//           "https://www.google.com/maps/dir//25.274504,51.498413"
//         ),
//     },
//     {
//       icon: "fal fa-envelope-open-text",
//       title: "Email:",
//       subtitle: loading ? "Loading..." : contact[0]?.email,
//       action: () => handleEmailClick("Support@bolt-tec.com"),
//     },
//     {
//       icon: "fal fa-phone-alt",
//       title: "Number Phone:",
//       subtitle: loading ? "Loading..." : contact[0]?.phoneNumber,
//       action: () => handleCallClick("+974 1234567"),
//     },
//   ];

//   return (
//     <>
//       <section className="contact__area pb-100 pt-95">
//         <div className="container">
//           <div className="row">
//             <div className="col-xl-6 col-lg-6">
//               {!contact[0] || !contact ? null : (
//                 <div className="contact__info">
//                   <h3>Find us here.</h3>
//                   <ul className="mb-55">
//                     {contactInfo.map((item) => (
//                       <li key={item.title} className="d-flex mb-35">
//                         <div className="contact__info-icon mr-20">
//                           <i
//                             onClick={item.action}
//                             style={{ cursor: "pointer" }}
//                             className={item.icon}
//                           ></i>
//                         </div>
//                         <div className="contact__info-content">
//                           <h6>{item.title}</h6>
//                           <span
//                             style={{ cursor: "pointer" }}
//                             onClick={item.action}
//                           >
//                             {item.subtitle}
//                           </span>
//                         </div>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//               <div className="contact__social">
//                 <ul>
//                   <SocialLinks />
//                 </ul>
//               </div>
//             </div>
//             <div className="col-xl-6 col-lg-6">
//               <div className="contact__form">
//                 <h3>Contact Us.</h3>
//                 {/* contact form start */}
//                 <ContactForm />
//                 {/* contact form end */}
//                 <p className="ajax-response"></p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default ContactArea;
