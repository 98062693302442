import { CSSProperties } from "react";
import videobol_bolt from "assets/img/bg/Rectangle-8.png";
import { BOLTColors } from "config/colors";
import { Link } from "react-router-dom";

const VideoBox = () => {
  return (
    <>
      <div
        className="video__area-df"
        style={{
          background: `url(${videobol_bolt})`,
          backgroundSize: "cover",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="container">
          <div className="video__content text-center">
            <h1 className="video-box-left-text">
              ENJOY YOUR CAMPING WITHOUT POWER LOSS
            </h1>
            <div className="read-more-parent-n">
              <Link to="/about" className="read-more-button-n">
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoBox;
