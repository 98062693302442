const menuDataProd: any = [
  // {
  //   link: "/",
  //   title: "Home",
  //   hasDropdown: true,
  //   megamenu: false,
  //   dropdownItems: [
  //     { link: "/", title: "Bolt-tec", ref: "slideshow" },
  //     { link: "/home-2", title: "About", ref: "about" },
  //     { link: "/home-3", title: "Categories", ref: "categories" },
  //     { link: "/home-4", title: "Featured Products", ref: "featured" },
  //     { link: "/home-5", title: "Latest Blogs", ref: "latestblogs" },
  //     { link: "/home-6", title: "Warranty", ref: "warranty" },
  //   ],
  // },
  {
    link: "/",
    title: "Home",
    hasDropdown: false,
    megamenu: false,
    dropdownItems: [],
    ref: "slideshow",
  },
  {
    link: "/new-arrivals",
    title: "New Arrivals",
    hasDropdown: false,
    megamenu: false,
    dropdownItems: [],
    ref: "about",
  },
  {
    link: "/products",
    title: "Products",
    hasDropdown: false,
    megamenu: false,
    dropdownItems: [],
    ref: "about",
  },
  {
    link: "/about",
    title: "About Us",
    hasDropdown: false,
    megamenu: false,
    dropdownItems: [],
    ref: "categories",
  },

  // {
  //   link: "/",
  //   title: "Featured Products",
  //   hasDropdown: false,
  //   megamenu: false,
  //   dropdownItems: [],
  //   ref: "featured",
  // },
  // {
  //   link: "/latestblogs",
  //   title: "Latest Blogs",
  //   hasDropdown: false,
  //   megamenu: false,
  //   dropdownItems: [],
  //   ref: "latestblogs",
  // },
  // {
  //   link: "/",
  //   title: "Warranty",
  //   hasDropdown: false,
  //   megamenu: false,
  //   dropdownItems: [],
  //   ref: "warranty",
  // },
  {
    link: "/contact",
    // link: "/contact-us",
    title: "Contact Us",
    hasDropdown: false,
    megamenu: false,
    dropdownItems: [],
    ref: "",
  },
];

export default menuDataProd;
