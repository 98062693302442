// import social_links from "data/social-data";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hook";

const SocialLinks = () => {
  const settings = useAppSelector((state) => state.setting.settingsInfo);

  const social_links = [
    {
      link: settings[0]?.facebook,
      icon: "fab fa-facebook-f",
      name: "Facebook",
    },
    {
      link: settings[0]?.instagram,
      icon: "fab fa-instagram",
      name: "Instagram",
    },
    {
      link: `https://api.whatsapp.com/send?phone=${settings[0]?.whatsappNb}&text=Hi`,
      icon: "fab fa-whatsapp",
      name: "Whatsapp",
    },
    // {
    //   link: settings[0]?.linkedin,
    //   icon: "fab fa-linkedin",
    //   name: "Twitter",
    // },
  ];

  return (
    <>
      {social_links?.map((s, i) => (
        <li key={i}>
          <Link to={s.link} target="_blank">
            <i
              style={{ fontSize: "large", color: "#ffffff" }}
              className={s.icon}
            ></i>
          </Link>
        </li>
      ))}
    </>
  );
};

export default SocialLinks;
