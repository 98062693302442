import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { SERVER_URL, handleOpenLink } from "helpers/helpers"
import "./prods2.css"
import { Tooltip } from "react-tooltip"
import { Img } from "react-image"
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share"
import { BOLTColors } from "config/colors"
// import { load } from "cheerio"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

const newPrev = true

type IProps = {
  item: any
  style_2?: boolean
  bottomShow?: boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const ProductDetailsNewDesign = ({
  item,
  style_2 = false!,
  bottomShow = true,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  let activeImage =
    item && item.itemColors && item.itemColors.length > 0
      ? item.itemColors[0].colorImage
      : item?.items?.hoverImage

  const [activeImg, setActiveImg] = useState(activeImage)
  const [activeIndex, setActiveIndex] = useState<number | string>("")
  const [hoverIndex, setHoverIndex] = useState<number | string>("")
  const [productDetails, setProductDetails] = useState("")
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  console.log({ item123: item })

  const handleImageActive = ({
    img,
    idx,
  }: {
    img: string
    idx: number | string
  }) => {
    setActiveImg(img)
    setActiveIndex(idx)
  }

  useEffect(() => {
    setActiveIndex(0)
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(item?.item?.description, 'text/html');
    // const mainContent = doc.querySelector('main')?.innerHTML;
    console.log({ jhasd: item?.items?.description })
  }, [])

  useEffect(() => {
    // Your HTML string containing product details
    if (item?.items?.description !== null) {
      const htmlString = item?.items?.description

      // if (!(htmlString instanceof String) || typeof htmlString !== "string") {
      //   return setProductDetails(htmlString)
      // }

      // // Load the HTML string into Cheerio
      // try {
      //   const $ = load(htmlString?.toString())
      //   // Extract the desired content using Cheerio selectors
      //   const desiredContent = $("h1, h2, h3, p, li").slice(0, 4).toString()

      //   setProductDetails(desiredContent)
      // } catch (error) {
      //   console.log(error)
      // }
      setProductDetails(htmlString)
    }
  }, [item?.items?.description])

  const capitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768) // Adjust the screen width as needed for mobile
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  if (newPrev) {
    return (
      <>
        <div className="row">
          {!style_2 && (
            <div className="col-xl-6 col-lg-6">
              {/* main pic  */}
              <div className="product__modal-box d-flex">
                {/* <div className="product__modal-nav mr-20"></div> */}
                {/* <div className="product__modal-nav mr-20">
                  <nav>
                    <div className="nav nav-tabs" id="product-details">
                      {item?.media?.map((item: any, i: any) => (
                        <a
                          key={i}
                          className={`nav-item nav-link cursor-pointer ${
                            item?.image === activeImg ? "active" : ""
                          }`}
                        >
                          <div
                            className="product__nav-img w-img"
                            onClick={() => setActiveImg(item?.image)}
                          >
                            <img
                              src={SERVER_URL + item?.image}
                              alt="product-img"
                              style={{ width: "100px" }}
                            />
                          </div>
                        </a>
                      ))}
                    </div>
                  </nav>
                </div> */}
                <div className="tab-content mb-20" id="product-detailsContent">
                  <div className="product__modal-img product__thumb w-img">
                    <img src={SERVER_URL + activeImg} alt="product-img" />
                  </div>
                </div>
              </div>
              <div className="nav" id="product-details">
                {item?.media?.map((item: any, i: any) => (
                  <a
                    key={i}
                    className={`nav-item nav-link cursor-pointer ${
                      item?.image === activeImg ? "active" : ""
                    }`}
                  >
                    <div
                      className="product__nav-img w-img"
                      onClick={() => setActiveImg(item?.image)}
                    >
                      <img
                        src={SERVER_URL + item?.image}
                        alt="product-img"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}

          {style_2 && (
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
              <div className="product__modal-box">
                <div className="tab-content mb-20" id="nav-tabContent">
                  <div className="product__modal-img w-img">
                    <img
                      src={SERVER_URL + activeImg}
                      alt="product-img"
                      width={327}
                      height={416}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              style_2
                ? "col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12"
                : "col-xl-6 col-lg-6"
            }
          >
            <div className="product__modal-content product__modal-content-2">
              <h4
                style={{
                  color: BOLTColors.footerBackgroundColorNavy,
                  fontSize: "45px",
                  textTransform: "capitalize",
                }}
              >
                <Link
                  style={{ color: BOLTColors.footerBackgroundColorNavy }}
                  to={`/product-details/${item?.items?._id}`}
                >
                  {item?.items?.title}
                </Link>
              </h4>
              {/* <div className="rating rating-shop mb-15">
                <ul>
                  <p>{item?.items?.skuNumber}</p>
                </ul>
                <span className="rating-no ml-10 rating-left">
                  {item?.items?.category?.title ?? "BOLT TEC PRODUCT"}
                </span>
              </div> */}
              {item?.itemColors?.length > 0 && (
                <>
                  <p
                    style={{
                      color: BOLTColors.footerBackgroundColorNavy,
                      fontWeight: "Bold",
                    }}
                  >
                    Color
                  </p>

                  <div className="rating rating-shop mb-15">
                    <ul>
                      {item?.itemColors?.map((img: any, i: number) => (
                        <button
                          onClick={() =>
                            handleImageActive({ img: img?.colorImage, idx: i })
                          }
                          style={{
                            marginBottom: "30px",
                            paddingLeft: 14,
                            paddingRight: 14,
                            paddingTop: 1,
                            paddingBottom: 1,
                            boxShadow: "0 0 5px rgba(192, 192, 192, 0.5)",
                            border: `1px solid silver`,
                            backgroundColor: "white",
                            marginRight: "10px",
                          }}
                        >
                          {img?.colorText}
                        </button>
                      ))}
                    </ul>
                    {/* <span className="rating-no ml-10 rating-left">
                  {item?.items?.category?.title ?? "BOLT TEC PRODUCT"}
                </span> */}
                  </div>
                </>
              )}
              {/* {isMobile && item?.itemColors?.length > 0 && (
                <nav style={{ marginBottom: "30px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "15px" }}>Colors</span>
                    {item?.itemColors?.map((img: any, i: number) => (
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={capitalizeFirstLetter(
                          img?.colorText
                        )}
                        key={i}
                        onClick={() =>
                          handleImageActive({ img: img?.colorImage, idx: i })
                        }
                        style={{
                          border:
                            activeIndex === i
                              ? `1px solid #d5ac4c`
                              : "1px solid transparent",
                          borderRadius: 1,
                          boxShadow: "0 0 5px rgba(192, 192, 192, 0.5)",
                          marginRight: "5px",
                        }}
                        className={`nav-item nav-link cursor-pointer ${
                          img?.colorImage === activeImg ? "active" : ""
                        }`}
                      >
                        <Tooltip
                          style={{ fontSize: 10, padding: 5, borderRadius: 0 }}
                          id="my-tooltip"
                        />
                        <div className="product__nav-img w-img">
                          <Img
                            src={SERVER_URL + img?.colorImage}
                            alt="product-img"
                            style={{ width: "40px", height: "40px" }}
                            loading="lazy"
                          />
                        </div>
                      </a>
                    ))}
                  </div>
                </nav>
              )} */}
              {isMobile && (
                <>
                  <p
                    style={{
                      fontSize: "8px",
                      fontWeight: "bold",
                      marginBottom: "0px",
                    }}
                  >
                    Click here to download user manual
                  </p>
                  <button
                    onClick={() =>
                      handleOpenLink(SERVER_URL + item?.items?.mediaUserManual)
                    }
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 2,
                      paddingBottom: 2,
                      boxShadow: "0 0 5px rgba(192, 192, 192, 0.5)",
                      backgroundColor: "#958d8e",
                      color: "white",
                      textTransform: "uppercase",
                      borderRadius: "5px",
                    }}
                  >
                    Download
                  </button>
                </>
              )}

              <div className="rating rating-shop mb-15 mt-15">
                <ul>
                  <p style={{ color: "#4d646b" }}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      SKU :
                    </span>{" "}
                    {item?.items?.skuNumber}
                  </p>
                  <p style={{ color: "#4d646b" }}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      Category :
                    </span>{" "}
                    {item?.items?.category?.title}
                  </p>
                  <p style={{ color: "#4d646b" }}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      Share :
                    </span>{" "}
                    <ul>
                      <li style={{ marginRight: "5px" }}>
                        <FacebookShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <FacebookIcon size={20} borderRadius={30} />
                        </FacebookShareButton>
                      </li>
                      <li style={{ marginRight: "5px" }}>
                        <TwitterShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <TwitterIcon size={20} borderRadius={30} />
                        </TwitterShareButton>
                      </li>
                      <li style={{ marginRight: "5px" }}>
                        <LinkedinShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <LinkedinIcon size={20} borderRadius={30} />
                        </LinkedinShareButton>
                      </li>
                      <li style={{ marginRight: "5px" }}>
                        <WhatsappShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <WhatsappIcon size={20} borderRadius={30} />
                        </WhatsappShareButton>
                      </li>
                    </ul>
                  </p>
                </ul>
              </div>

              <div className="hide-on-mobile product__modal-des mb-30">
                <p
                  dangerouslySetInnerHTML={{
                    __html: productDetails.substring(0, 500) + " ...",
                  }}
                ></p>
              </div>

              {/* {!isMobile && item?.itemColors?.length > 0 && (
                <nav>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "15px" }}>Colors</span>
                    {item?.itemColors?.map((img: any, i: number) => (
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={capitalizeFirstLetter(
                          img?.colorText
                        )}
                        key={i}
                        onClick={() =>
                          handleImageActive({ img: img?.colorImage, idx: i })
                        }
                        style={{
                          border:
                            activeIndex === i
                              ? `1px solid #d5ac4c`
                              : "1px solid transparent",
                          borderRadius: 1,
                          boxShadow: "0 0 5px rgba(192, 192, 192, 0.5)",
                          marginRight: "5px",
                        }}
                        className={`nav-item nav-link cursor-pointer ${
                          img?.colorImage === activeImg ? "active" : ""
                        }`}
                      >
                        <Tooltip
                          style={{ fontSize: 10, padding: 5, borderRadius: 0 }}
                          id="my-tooltip"
                        />
                        <div className="product__nav-img w-img">
                          <Img
                            src={SERVER_URL + img?.colorImage}
                            alt="product-img"
                            style={{ width: "40px", height: "40px" }}
                            loading="lazy"
                          />
                        </div>
                      </a>
                    ))}
                  </div>
                </nav>
              )} */}

              {!isMobile && (
                <>
                  <p
                    style={{
                      fontSize: "8px",
                      fontWeight: "bold",
                      marginBottom: "0px",
                    }}
                  >
                    Click here to download user manual
                  </p>
                  <button
                    onClick={() =>
                      handleOpenLink(SERVER_URL + item?.items?.mediaUserManual)
                    }
                    style={{
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 2,
                      paddingBottom: 2,
                      boxShadow: "0 0 5px rgba(192, 192, 192, 0.5)",
                      backgroundColor: "#958d8e",
                      color: "white",
                      textTransform: "uppercase",
                      borderRadius: "5px",
                    }}
                  >
                    Download
                  </button>
                </>
              )}
            </div>
          </div>
          <Box sx={{ width: "100%", mt: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor="inherit"
                TabIndicatorProps={{ style: { backgroundColor: "gray" } }}
              >
                <Tab
                  disableRipple
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  label="Description"
                  {...a11yProps(0)}
                />
                {/* <Tab
                  disabled
                  sx={{ textTransform: "capitalize", fontWeight: "bold" }}
                  label="Shipping & Delivery Available"
                  {...a11yProps(1)}
                /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div className="mb-30">
                <p
                  dangerouslySetInnerHTML={{ __html: item?.items?.description }}
                ></p>
              </div>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>
              Item Two
            </CustomTabPanel> */}
          </Box>
        </div>
      </>
    )
  } else
    return (
      <>
        <div className="row">
          {!style_2 && (
            <div className="col-xl-6 col-lg-6">
              <div className="product__modal-box d-flex">
                <div className="product__modal-nav mr-20">
                  <nav>
                    <div className="nav nav-tabs" id="product-details">
                      {item?.itemColors?.map(
                        (
                          img: string | undefined,
                          i: React.Key | null | undefined
                        ) => (
                          <a
                            key={i}
                            className={`nav-item nav-link cursor-pointer ${
                              img === activeImg ? "active" : ""
                            }`}
                          >
                            <div
                              className="product__nav-img w-img"
                              onClick={() => {}}
                            >
                              <img
                                src={SERVER_URL + activeImg}
                                alt="product-img"
                                width={92}
                                height={117}
                              />
                            </div>
                          </a>
                        )
                      )}
                    </div>
                  </nav>
                </div>
                {/* <div className="tab-content mb-20" id="product-detailsContent">
                <div className="product__modal-img product__thumb w-img">
                  <img
                    src={activeImg}
                    alt="product-img"
                    width={418}
                    height={534}
                  />
                  <div className="product__sale">
                    {product.new && <span className="new">new</span>}
                    {product.discount && (
                      <span className="percent">-{product.discount}%</span>
                    )}
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          )}
          {/* main img  */}
          {!style_2 && (
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12">
              <div className="product__modal-box">
                <div className="tab-content mb-20" id="nav-tabContent">
                  <div className="product__modal-img w-img">
                    <Img
                      loading="lazy"
                      src={`${SERVER_URL}${activeImg}`}
                      alt="product-img"
                      width={327}
                      height={416}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={
              style_2
                ? "col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12"
                : "col-xl-6 col-lg-6"
            }
          >
            <div className="product__modal-content product__modal-content-2">
              <h2
                style={{
                  color: BOLTColors.footerBackgroundColorNavy,
                  fontSize: "50px",
                }}
              >
                <Link
                  style={{
                    color: BOLTColors.footerBackgroundColorNavy,
                    fontSize: "50px",
                  }}
                  to={`/product-details/${item?.items?._id}`}
                >
                  {item?.items?.title}
                </Link>
              </h2>
              <div className="rating rating-shop mb-15">
                <ul>
                  <p>{item?.items?.skuNumber}</p>
                </ul>
                <span className="rating-no ml-10 rating-left">
                  {/* Ice Making Machines  - just needs hosted backend */}
                  {item?.items?.category?.title ?? "BOLT TEC PRODUCT"}
                </span>
              </div>

              <div className="product__modal-des mb-30">
                <p>{item?.items?.description}</p>
              </div>
              {item?.itemColors?.length == 0 ? null : (
                <nav>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    // className="nav justify-content-start"
                  >
                    <span style={{ marginRight: "15px" }}>Colors</span>
                    {item?.itemColors?.map((img: any, i: number) => (
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={capitalizeFirstLetter(
                          img?.colorText
                        )}
                        key={i}
                        onClick={() =>
                          handleImageActive({ img: img?.colorImage, idx: i })
                        }
                        style={{
                          border:
                            activeIndex === i
                              ? `1px solid #d5ac4c`
                              : "1px solid transparent",
                          borderRadius: 1,
                          boxShadow: "0 0 5px rgba(192, 192, 192, 0.5)",
                          marginRight: "5px",
                        }}
                        className={`nav-item nav-link cursor-pointer ${
                          img?.colorImage === activeImg ? "active" : ""
                        }`}
                      >
                        <Tooltip
                          style={{ fontSize: 10, padding: 5, borderRadius: 0 }}
                          id="my-tooltip"
                        />
                        <div className="product__nav-img w-img">
                          <Img
                            src={SERVER_URL + img?.colorImage}
                            alt="product-img"
                            style={{ width: "40px", height: "40px" }}
                            loading="lazy"
                          />
                        </div>
                      </a>
                    ))}
                  </div>
                </nav>
              )}

              {bottomShow && (
                <div style={{ marginTop: "25px" }}>
                  <div className="product__share">
                    <span>Share </span>
                    <ul>
                      <li>
                        <FacebookShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <i className="fab fa-facebook-f"></i>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <i className="fab fa-twitter"></i>
                        </TwitterShareButton>
                      </li>
                      <li>
                        <LinkedinShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </LinkedinShareButton>
                      </li>
                      <li>
                        <WhatsappShareButton
                          url={`${item?.items?.title}\n${item?.items?.description}\n Shared from:  https://boltproducts.net/product-details/${item?.items?._id}`}
                        >
                          <i className="fab fa-whatsapp"></i>
                        </WhatsappShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
}

export default ProductDetailsNewDesign
