import React from "react";
// import { promises as fs } from "fs";
// import { Metadata } from "next";
import Wrapper from "layout/wrapper";
import Header from "layout/headers/header";
import Breadcrumb from "components/common/breadcrumb";
import Footer from "layout/footers/footer";
import ProductDetailsUpper from "components/product-details/product-details-upper";
import ProductDetailsBottom from "components/product-details/product-details-bottom";
import RelatedProducts from "components/products/related-products";
import { jsonData } from "../home-6/product-data";
import { useAppSelector } from "redux/hook";

// export const metadata: Metadata = {
//   title: "Shop Details Page",
// };

export default function ProductDetailsPage() {
  //   const file = await fs.readFile(
  //     process.cwd() + "/src/app/product-data.json",
  //     "utf8"
  //   );
  //   const product_data = JSON.parse(file);
  const product = [...jsonData][0];
  const settings = useAppSelector((state) => state.setting.settingsInfo);
  const productBanner = settings[0]?.productsBanner;

  return (
    <Wrapper>
      {/* header start */}
      <Header />
      {/* header end */}

      <main>
        {/* breadcrumb start */}
        <Breadcrumb
          bannerImg={productBanner}
          title="Product Details"
          subtitle="Product Details"
        />
        {/* breadcrumb end */}

        {/* shop details upper area start */}
        <section className="shop__area pb-65">
          <div className="shop__top grey-bg-6 pt-100 pb-90">
            <div className="container">
              <ProductDetailsUpper product={product} />
              <ProductDetailsBottom product={product} />
            </div>
          </div>
        </section>
        {/* shop details upper area end */}

        {/* related products start */}
        <RelatedProducts product_data={jsonData} product={product} />
        {/* related products end */}
      </main>

      {/* footer start */}
      <Footer />
      {/* footer end */}
    </Wrapper>
  );
}
