import React, { useEffect, useState } from "react"
// import Image from "next/image";
// import Link from "next/link";
// import dynamic from "next/dynamic";
import { Link } from "react-router-dom"
// internal
import ExtraInfo from "./header-com/extra-info"
import NavManus from "./header-com/nav-manus"
// import useCartInfo from "hooks/use-cart-info";
import useSticky from "hooks/use-sticky"
import logo from "assets/img/logo/logo.png"
import SearchPopup from "./header-com/search-popup"
import MiniCart from "./header-com/mini-cart"
// const OffCanvas = React.lazy(() => import("components/common/offcanvas"));
import OffCanvas from "components/common/offcanvas"
import logo_btec from "../../assets/img/bolt-tec/Bolt_black_logo.png"
import NavManusProd from "./header-com/nav-manus-prod"
import "./header4.css"

const Dev = false

const HeaderFour = () => {
  const { sticky } = useSticky()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  // const { quantity } = useCartInfo();
  const [showSidebar, setShowSidebar] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768) // Adjust the screen width as needed for mobile
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  if (Dev) {
    // <<-------------------------------------------- DEV -------------------------------------------->>
    return (
      <>
        {" "}
        <header>
          <div
            id="header-sticky"
            className={`header__area header__transparent header__transparent-2 pt-15 pb-15 box-25 ${
              sticky ? "sticky" : ""
            }`}
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-3">
                  <div className="logo">
                    <Link to="/">
                      <img
                        style={{ maxWidth: "100%", maxHeight: 35 }}
                        // style={{ maxWidth: 130, maxHeight: 80 }}
                        src={logo_btec}
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-1 col-sm-1">
                  <div className="main-menu main-menu-3 d-none d-lg-block p-relative">
                    <nav>
                      <NavManus />
                    </nav>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-8">
                  <div className="header__right p-relative d-flex justify-content-between justify-content-sm-end align-items-center">
                    <div className="mobile-menu-btn d-lg-none">
                      <button
                        className="mobile-menu-toggle"
                        onClick={() => setShowSidebar(true)}
                      >
                        <i className="fas fa-bars"></i>
                      </button>
                    </div>
                    <div className="header__action">
                      <ul>
                        <li>
                          <button
                            className="search-toggle"
                            onClick={() => setShowSearch(true)}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </li>
                        {/* <li>
                  <button className="cart">
                    <i className="fas fa-cart-plus"></i>
                    <span className="cart-number-2">{quantity}</span>
                  </button>
                  <nav>
                    <MiniCart />
                  </nav>
                </li> */}
                        {/* <li>
                          <button>
                            <i className="fas fa-user"></i>
                          </button>
                          <ExtraInfo />
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* search popup start */}
        <SearchPopup showSearch={showSearch} setShowSearch={setShowSearch} />
        {/* search popup end */}
        {/* offcanvas start */}
        <OffCanvas
          openMobileMenus={showSidebar}
          setOpenMobileMenus={setShowSidebar}
        />
        {/* offcanvas end */}
      </>
    )
  }
  // <<-------------------------------------------- PROD -------------------------------------------->>
  else
    return (
      <>
        <header>
          <div
            id="header-sticky"
            style={{
              backgroundColor: isMobile ? undefined : "white",
              height: isMobile ? undefined : "110px",
            }}
            className={`header__area header__transparent header__transparent-2 pt-15 pb-15 box-25 ${
              sticky ? "sticky" : ""
            }`}
          >
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-3">
                  <div className="logo">
                    <Link to="/">
                      <img
                        className="image-container"
                        style={{
                          position: isMobile ? "absolute" : undefined,
                          left: isMobile ? "40%" : undefined,
                          top: isMobile ? "20%" : undefined,
                        }}
                        src={logo_btec}
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-1 col-sm-1">
                  <div className="main-menu main-menu-3 d-none d-lg-block p-relative">
                    <nav>
                      <NavManusProd />
                    </nav>
                  </div>
                </div>
                <div
                  style={{ marginTop: "-15px" }}
                  className="col-xl-3 col-lg-4 col-md-7 col-sm-8"
                >
                  <div className="header__right p-relative d-flex justify-content-between justify-content-sm-end align-items-center">
                    <div className="mobile-menu-btn d-lg-none">
                      <button
                        className="mobile-menu-toggle"
                        onClick={() => setShowSidebar(true)}
                      >
                        <i className="fas fa-bars"></i>
                      </button>
                    </div>
                    <div className="header__action">
                      <ul>
                        <li>
                          <button
                            className="search-toggle"
                            onClick={() => setShowSearch(true)}
                          >
                            <i className="fas fa-search"></i>
                          </button>
                        </li>
                        {/* <li>
                        <button className="cart">
                          <i className="fas fa-cart-plus"></i>
                          <span className="cart-number-2">{quantity}</span>
                        </button>
                        <nav>
                          <MiniCart />
                        </nav>
                      </li> */}
                        {/* <li>
                          <button>
                            <i className="fas fa-user"></i>
                          </button>
                          <ExtraInfo />
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* search popup start */}
        <SearchPopup showSearch={showSearch} setShowSearch={setShowSearch} />
        {/* search popup end */}

        {/* offcanvas start */}
        <OffCanvas
          openMobileMenus={showSidebar}
          setOpenMobileMenus={setShowSidebar}
        />
        {/* offcanvas end */}
      </>
    )
}

export default HeaderFour
